import React from "react";
import { observer } from "mobx-react";
import { Trans, useTranslation } from "@tecma/i18n";
import { Button, IconButton, Portal } from "@material-ui/core";
import SvgLoader from "components/generic/SvgLoader";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Warning from "assets/images/warning-icon.svg";
import Error from "assets/images/error-icon.svg";
import Success from "assets/images/success-icon.svg";

import "styles/support-alert.scss";

const SupportAlert = observer(({ container, isOpen, onCancel, onClose, alertType, jiraKey }) => {
  const { t } = useTranslation();

  //warning modal
  const warningHeader = (
    <div className="support-alert-header-warning">
      <p className="support-alert-warning"> {t("businessplatform.support.alert.warning")}</p>
      <SvgLoader className="support-alert-icon" data={Warning} alt="alert" />
      <h2> {t("businessplatform.support.alert.warning.title")}</h2>
    </div>
  );

  const warningContent = (
    <div className="support-alert-content-warning">
      <p>
        <Trans
          i18nKey="businessplatform.support.alert.warning.cancelExplanation"
          components={{ 1: <span></span> }}
        />
      </p>
      <p>
        <Trans
          i18nKey="businessplatform.support.alert.warning.proceedExplanation"
          components={{ 1: <span></span> }}
        />
      </p>
    </div>
  );

  const warningFooter = (
    <div className="support-alert-footer-warning">
      <Button variant="outlined" color="primary" size="large" onClick={onClose}>
        {t("businessplatform.support.cancel")}
      </Button>
      <Button variant="contained" color="primary" size="large" onClick={onCancel}>
        {t("businessplatform.support.proceed")}
      </Button>
    </div>
  );

  //error modal
  const errorHeader = (
    <div className="support-alert-header-error">
      <p className="support-alert-error"> {t("businessplatform.support.alert.error")}</p>
      <SvgLoader className="support-alert-icon" data={Error} alt="error" />
      <h2> {t("businessplatform.support.alert.error.title")}</h2>
    </div>
  );

  const errorContent = (
    <div className="support-alert-content-error">
      <p>{t("businessplatform.support.alert.error.content")}</p>
      {/* <p>{t("businessplatform.support.alert.error.tryLater")}</p> */}
      {/* <p className="support-email">
        <a className="support-email" href="mailto:email@tecmasolutions.com">
          email@tecmasolutions.com
        </a>
      </p> */}
    </div>
  );

  const errorFooter = (
    <div className="support-alert-footer-error">
      <Button variant="outlined" color="primary" size="large" onClick={onClose}>
        {t("businessplatform.support.close")}
      </Button>
    </div>
  );

  //success modal
  const successHeader = (
    <div className="support-alert-header-success">
      <p className="support-alert-success"> {t("businessplatform.support.alert.success")}</p>
      <SvgLoader className="support-alert-icon" data={Success} alt="error" />
      <h2> {t("businessplatform.support.alert.success.title")}</h2>
      <p>
        <span className="ticketNumber">{t("businessplatform.support.alert.ticketNumber")}</span>
        <span>&nbsp;&nbsp;{jiraKey}</span>
      </p>
    </div>
  );

  const successContent = (
    <div className="support-alert-content-success">
      <p>
        <Trans i18nKey="businessplatform.support.alert.success.content" components={{ 1: <b /> }} />
      </p>
      <p>{t("businessplatform.support.alert.success.content2")}</p>
      {/* <p>{t("businessplatform.support.alert.success.content3")}</p> */}
    </div>
  );

  const successFooter = (
    <div className="support-alert-footer-success">
      <Button variant="outlined" color="primary" size="large" onClick={onClose}>
        {t("businessplatform.support.ok")}
      </Button>
    </div>
  );

  return (
    isOpen && (
      <Portal container={container}>
        <div className="support-alert-wrapper">
          <div className="support-alert-container">
            <IconButton className="support-alert-close" onClick={onClose}>
              <CloseRoundedIcon />
            </IconButton>
            <div className="support-alert-header">
              {alertType === "warning" && warningHeader}
              {alertType === "error" && errorHeader}
              {alertType === "success" && successHeader}
            </div>
            <div className="support-alert-content">
              {alertType === "warning" && warningContent}
              {alertType === "error" && errorContent}
              {alertType === "success" && successContent}
            </div>
            <div className="support-alert-footer">
              {alertType === "warning" && warningFooter}
              {alertType === "error" && errorFooter}
              {alertType === "success" && successFooter}
            </div>
          </div>
        </div>
      </Portal>
    )
  );
});

export default SupportAlert;
