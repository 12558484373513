import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Alert } from "@tecma/ds";
import { useTranslation } from "@tecma/i18n";

const Form = observer(({ children, onSubmit, className, showAlert, alertProps, ...rest }) => {
  const { t } = useTranslation();
  const classList = classNames("login-wrapper-content", className);

  return onSubmit ? (
    <form onSubmit={onSubmit} className={classList} {...rest}>
      {showAlert && <Alert type='error' {...alertProps} title={t(alertProps?.title)} />}
      {children}
    </form>
  ) : (
    <div className={classList} {...rest}>
      {showAlert && <Alert type='error' {...alertProps} title={t(alertProps?.title)} />}
      {children}
    </div>
  );
});
export default Form;
