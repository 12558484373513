import React, { useState, useEffect } from "react";
import { useStore } from "store/storeUtils";
import { useHistory, useLocation, Link } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "@tecma/i18n";
import { logger } from "@tecma/logs-fe";
import { Drawer, Header as TecmaHeader } from "@tecma/ds";
import { useMutation } from "@apollo/client";
import Client from "gqlclient/Client";
import logo from "assets/images/tecma_logo.svg";

import { couldOpenSupportModal } from "utils";
import { logout } from "utils/auth";
import getOrderedLanguages from "utils/getOrderedLanguages";
import Support from "components/common/Support";

import "./header.scss";

const Header = observer((props) => {
  const { setLang } = props;
  const { t } = useTranslation();
  const store = useStore();
  const history = useHistory();
  const location = useLocation();
  const [logoutMutation] = useMutation(Client.LOGOUT);
  const [openModalSupport, setOpenModalSupport] = useState(false);

  const handleLogout = () => {
    logoutMutation()
      .then((res) => {
        if (res.data && res.data.logout === "Ok") {
          logout(history);
        }
      })
      .catch((err) => logger.error(err));
  };
  const openResources = () => window.open(`http://resources.tecmasolutions.com/`);
  const handleChangeLang = (selectedLanguage) => {
    getOrderedLanguages(store.configLanguages, selectedLanguage);
    const trueLanguage = selectedLanguage.split("-")[0];
    const locale = selectedLanguage.split("-")[1];
    setLang(trueLanguage, locale);
  };
  const handleModalOpen = (e) => {
    e !== undefined && e.stopPropagation();
    setOpenModalSupport(!openModalSupport);
  };

  useEffect(() => {
    if (store?.area === "rent") {
      const shouldOpenSupportModal = couldOpenSupportModal(location.search);
      if (shouldOpenSupportModal) {
        setOpenModalSupport(true);
      }
    }
  }, []);

  return (
    <>
      <TecmaHeader
        logo={logo}
        logoWrapper={LogoWrapper}
        desktopHeaderContent={
          <>
            <TecmaHeader.Item
              iconName='book-open'
              rightIconName='external-link'
              label={t("businessplatform.resources")}
              onClick={openResources}
            />
            {store.loggedUser && (
              <TecmaHeader.Item label={store.loggedUserFullname} iconName='user-circle'>
                <TecmaHeader.MenuItem
                  title={store.loggedUserFullname}
                  subtitle={t(
                    `businessplatform.${store.area === "sale" ? "sell" : "rent"}.${
                      store.loggedUser.role
                    }`,
                  )}
                />
                <TecmaHeader.Divider />
                <TecmaHeader.MenuItem title={t("businessplatform.logout")} onClick={handleLogout} />
              </TecmaHeader.Item>
            )}
            <TecmaHeader.LanguageSelector
              currentLanguage={store.loggedUser?.language + "-" + store.loggedUser?.locale}
              languages={store.configLanguages}
              onChangeLanguage={handleChangeLang}
            />
            {store.area === "rent" && (
              <TecmaHeader.Item
                label={t("businessplatform.support.navButton")}
                iconName='headset'
                onClick={handleModalOpen}
              />
            )}
          </>
        }
        mobileHeaderContent={
          store.area === "rent" && <TecmaHeader.Item iconName='headset' onClick={handleModalOpen} />
        }
        mobileDrawerContent={
          <>
            <Drawer.Content>
              {store.loggedUser && (
                <Drawer.Avatar
                  avatarProps={{
                    text: store.loggedUserFullname
                      .match(/(\b\S)?/g)
                      .join("")
                      .match(/(^\S|\S$)?/g)
                      .join("")
                      .toUpperCase(),
                  }}
                  title={store.loggedUserFullname}
                  subtitle={t(
                    `businessplatform.${store.area === "sale" ? "sell" : "rent"}.${
                      store.loggedUser.role
                    }`,
                  )}
                />
              )}

              <div>
                <Drawer.Item
                  label={t("businessplatform.resources")}
                  iconName='book-open'
                  rightIcon='external-link'
                  menuLayout='accordion'
                  onClick={openResources}
                />
                <Drawer.Item
                  label={t("businessplatform.languages")}
                  iconName='translate'
                  rightIcon='chevron-right'
                  menuLayout='page'
                >
                  <Drawer.Languages
                    currentLanguage={store.loggedUser?.language + "-" + store.loggedUser?.locale}
                    onChangeLanguage={handleChangeLang}
                    languages={store.configLanguages}
                  />
                </Drawer.Item>
              </div>
            </Drawer.Content>

            {store.loggedUser && (
              <Drawer.Footer>
                <Drawer.Item
                  label={t("businessplatform.logout")}
                  iconName='logout'
                  onClick={handleLogout}
                />
              </Drawer.Footer>
            )}
          </>
        }
      />
      <Support isOpen={openModalSupport} closeModal={handleModalOpen} />
    </>
  );
});

export default Header;
// eslint-disable-next-line react/prop-types
const LogoWrapper = ({ children }) => <Link to='/'>{children}</Link>;
