export const MAX_SUBJECT_LENGTH = 140;
export const MAX_DESCRIPTION_LENGTH = 1000;

export const ISSUE_TYPES = [
  {
    label: "operationalSupport",
    type: "SUPPORT",
  },
  {
    label: "anomalyOrBlockingIssue",
    type: "BUG",
  },
  {
    label: "incompliantContent",
    type: "BUG",
  },
  {
    label: "personalAreaSupport",
    type: "SUPPORT",
  },
  {
    label: "userRegistrationSupport",
    type: "SUPPORT",
  },
  {
    label: "accountActivation",
    type: "SUPPORT",
  },
  {
    label: "otherRequests",
    type: "SUPPORT",
  },
];

export const BROWSER_ICONS_NAMES = {
  Chrome: "chrome",
  Safari: "safari",
  Edge: "microsoft-edge",
  Firefox: "firefox",
};

export const SUPPORT_FORMS_STATE = {
  subject: "",
  description: "",
  issues: [],
  products: [],
  devices: [],
  browsers: [],
};

export const SUPPORT_ERRORS_STATE = {
  subject: null,
  description: null,
  issues: null,
  products: null,
  devices: null,
  browsers: null,
};
