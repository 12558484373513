export const getPasswordValidators = (t) => ({
  upperAndLower: t("businessplatform.resetPassword.upperAndLowerCriteria"),
  atLeastANumber: t("businessplatform.resetPassword.numberCriteria"),
  atLeastASymbol: t("businessplatform.resetPassword.symbolCriteria"),
  minimumMaximumLength: t("businessplatform.resetPassword.sevenCharacterCriteria"),
});

export const getGraphErrorCode = (response) =>
  response?.errors?.[0]?.extensions?.response?.body?.name;

export const getErrorName = (error) => error?.graphQLErrors?.[0].extensions?.response?.body?.name;
