import React, { useState } from "react";
import { observer } from "mobx-react";
import { Trans, useTranslation } from "@tecma/i18n";
import { Button, Input, Checkbox, CheckboxGroup, Radio, RadioGroup, Spinner } from "@tecma/ds";
import { getValidatorsPass } from "@tecma/ds/lib/components/Input/Input";

import useActivateAccount from "hooks/useActivateAccount";
import { LoginWrapper } from "components/LoginWrapper";
import { getPasswordValidators } from "pages/utils";

const ActivateAccountContent = observer(({ onSuccess }) => {
  const { t } = useTranslation();

  const [validatorResults, setValidatorResults] = useState({});

  const validators = getPasswordValidators(t);

  const handleNewPasswordChange = (e) => {
    const validatorPass = getValidatorsPass(e.target.value, validators);
    const allValidatorsOk = validatorPass ? Object.values(validatorPass).every((val) => val) : null;
    setValidatorResults(validatorPass);
    handleOnChangeValue({ type: "newPassword", payload: e.target.value }, allValidatorsOk);
  };

  const {
    formValues,
    errorsState,
    formError,
    isLoading,
    isEnabledNewsletter,
    isDisabledConfirmAction,
    isDisabledPrivacy,
    privacyDocumentHref,
    onCompleteRegistration,
    handleOnChangeValue,
    validatingToken,
  } = useActivateAccount(onSuccess);

  const newPasswordError =
    (errorsState.newPassword && t(errorsState.newPassword)) ||
    (formValues.newPassword &&
      !errorsState.passwordIsValid &&
      t("businessplatform.resetPassword.passwordMust"));

  return (
    <LoginWrapper className='activate-account' loading={validatingToken}>
      <LoginWrapper.Header>
        <span className='login-wrapper-header-title'>
          {t("businessplatform.completeRegistration")}
        </span>
      </LoginWrapper.Header>
      <LoginWrapper.Content showAlert={!!formError} alertProps={{ title: formError }}>
        <div className='login-wrapper-content-wrapper'>
          <Input
            className='login-wrapper-content-input'
            value={formValues.newPassword}
            helpText={newPasswordError}
            label={t("businessplatform.resetPassword.createPassword")}
            name='text-input-2'
            status={newPasswordError && "error"}
            onChange={handleNewPasswordChange}
            validators={validators}
            validatorResults={validatorResults}
            type='password'
            placeholder={t("businessplatform.resetPassword.placeholder.createPassword")}
          />
          <Input
            className='login-wrapper-content-input'
            name='text-input-3'
            label={t("businessplatform.resetPassword.repeatPassword")}
            value={formValues.confirmPassword}
            helpText={t(errorsState.confirmPassword)}
            status={errorsState.confirmPassword && "error"}
            onChange={(e) => {
              handleOnChangeValue({ type: "confirmPassword", payload: e.target.value });
            }}
            type='password'
            placeholder={t("businessplatform.resetPassword.placeholder.repeatPassword")}
          />
          {isEnabledNewsletter && (
            <>
              <RadioGroup
                row
                error={errorsState.subscription}
                helperText={t(errorsState.subscription)}
                label={t("businessplatform.resetPassword.newsletter")}
                onChange={(_, value) =>
                  handleOnChangeValue({ type: "subscription", payload: value === "true" })
                }
              >
                <Radio
                  value='true'
                  size='small'
                  label={t("businessplatform.resetPassword.label.yes")}
                  checked={formValues.subscription === true}
                  color={errorsState.subscription ? "error" : "default"}
                />
                <Radio
                  value='false'
                  size='small'
                  label={t("businessplatform.resetPassword.label.no")}
                  checked={formValues.subscription === false}
                  color={errorsState.subscription ? "error" : "default"}
                />
              </RadioGroup>
              <CheckboxGroup
                error={errorsState.privacyPolicy}
                helpText={errorsState.privacyPolicy && t(errorsState.privacyPolicy)}
              >
                <Checkbox
                  checked={formValues.privacyPolicy}
                  size='small'
                  onChange={() => {
                    handleOnChangeValue({ type: "privacyPolicy" });
                  }}
                  color={errorsState.privacyPolicy ? "error" : "default"}
                  disabled={isDisabledPrivacy}
                  label={
                    <Trans
                      i18nKey='businessplatform.resetPassword.privacyPolicy'
                      components={{
                        a: (
                          <a
                            className='login-wrapper-content-link'
                            rel='noreferrer'
                            target='_blank'
                            href={privacyDocumentHref}
                          />
                        ),
                      }}
                    />
                  }
                />
              </CheckboxGroup>
            </>
          )}
        </div>
        <Button disabled={isDisabledConfirmAction} fluid onClick={onCompleteRegistration}>
          {isLoading && <Spinner />}
          {t("businessplatform.resetPassword.complete")}
        </Button>
      </LoginWrapper.Content>
    </LoginWrapper>
  );
});

export default ActivateAccountContent;
