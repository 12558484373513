import React from "react";
import { Route, Redirect } from "react-router-dom";
import PageTemplate from "pages/PageTemplate";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { useStore } from "store/storeUtils";
import Cookies from "js-cookie";

const PrivateRoute = observer((props) => {
  const store = useStore();
  const { exact, path, ToRender, title, goBack, breadcrumb } = props;

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        return Cookies.get("jwt") &&
          (store.checkingJwt ||
            store.checkingJwt == null ||
            store.loggedUser ||
            !store.accountManagerEnabled) ? (
          <PageTemplate
            BodyComponent={ToRender}
            title={title}
            goBack={goBack}
            breadcrumb={breadcrumb}
            {...props}
          />
        ) : !store.baseUrl ? (
          <></>
        ) : (
          <Redirect exact to='/login' />
        );
      }}
    />
  );
});

PrivateRoute.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  ToRender: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  goBack: PropTypes.bool,
  breadcrumb: PropTypes.string,
};

PrivateRoute.defaultProps = {
  exact: false,
  goBack: false,
};

export default PrivateRoute;
