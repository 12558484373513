import React from "react";
import PropTypes from "prop-types";
import { Button, Card, Icon } from "@tecma/ds";
import { useTranslation } from "@tecma/i18n";

import ServiceStatusTag from "../ServiceStatusTag/ServiceStatusTag";

import "./toolBox.scss";

const ToolBox = (props) => {
  const { tool } = props;
  const imgsUrl = `${process.env.REACT_APP_BUCKET_BASEURL}/global/img/tools`;
  const { t } = useTranslation();

  return (
    <Card className='bss-toolBox' fluid borderLess>
      <Card.Media>
        <img
          src={`${imgsUrl}/${tool.name.toLowerCase()}.jpeg?v=${new Date().getDay()}`}
          alt={tool.name}
          title={tool.name}
        />
      </Card.Media>
      <Card.Container className='toolBox-container'>
        <Card.Header className='toolBox-header'>
          <Icon isLogo iconName={`logo-${tool.name.replace("-", "")}`} size='small' />
          <p>{tool.name}</p>
        </Card.Header>
        <Card.Content className='toolBox-content'>
          <p>{tool.description}</p>
        </Card.Content>
        <ServiceStatusTag status={tool.url ? tool.status : "comingSoon"} />
      </Card.Container>
      <Card.Footer className='toolBox-footer'>
        <Button
          iconName={tool.name === "CADLibrary" ? "download" : "external-link"}
          rightIcon
          disabled={tool.status.toLowerCase() !== "available"}
          onClick={() => {
            window.open(tool.url, "_blank");
          }}
          fluid
        >
          {tool.name === "CADLibrary"
            ? t("businessplatform.download")
            : t("businessplatform.enter")}
        </Button>
      </Card.Footer>
    </Card>
  );
};

ToolBox.propTypes = {
  tool: PropTypes.object,
};

export default ToolBox;
