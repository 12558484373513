// const httpStatusCodes = require('http-status');

/**
 * copy of object defined on graphql-sell
 */
export const ERRORS = {
  COMMON: {
    // common errors for all endpoints
    INVALID_TOKEN: {
      code: "e_user001",
      name: "INVALID_TOKEN",
      message: "The provided token is invalid",
      // httpStatus: httpStatusCodes.UNAUTHORIZED,
    },
    WRONG_PASSWORD: {
      code: "e_user002",
      name: "WRONG_PASSWORD",
      message: "The provided password is incorrect.",
      // httpStatus: httpStatusCodes.BAD_REQUEST,
    },
    EXPIRED_TOKEN: {
      code: "e_user003",
      name: "EXPIRED_TOKEN",
      message: "The provided token has expired.",
      // httpStatus: httpStatusCodes.UNAUTHORIZED,
    },
    PASSWORD_EQUALS_USER_DETAILS: {
      code: "e_user004",
      name: "PASSWORD_EQUALS_USER_DETAILS",
      message: "The new password cannot be the same as user details.",
      // httpStatus: httpStatusCodes.BAD_REQUEST,
    },
    NOT_SECURE_PASSWORD: {
      code: "e_user005",
      name: "NOT_SECURE_PASSWORD",
      message: "The new password does not meet security requirements.",
      // httpStatus: httpStatusCodes.BAD_REQUEST,
    },
    PASSWORD_ALREADY_USED: {
      code: "e_user006",
      name: "PASSWORD_ALREADY_USED",
      message: "The new password has already been used before.",
      // httpStatus: httpStatusCodes.CONFLICT,
    },
    USER_DISABLED: {
      code: "e_user007",
      name: "USER_DISABLED",
      message: "The user is currently disabled.",
      // httpStatus: httpStatusCodes.FORBIDDEN,
    },
    PASSWORD_EXPIRED: {
      code: "e_user008",
      name: "PASSWORD_EXPIRED",
      message: "The password is expired.",
      // httpStatus: httpStatusCodes.UNAUTHORIZED,
    },
  },
  CONFIRM_USER_BP: {},
  CHANGE_PASSWORD_BP: {},
};

export const ERROR_CODES = {
  INVALID_TOKEN: "INVALID_TOKEN",
  WRONG_PASSWORD: "WRONG_PASSWORD",
  EXPIRED_TOKEN: "EXPIRED_TOKEN",
  PASSWORD_EQUALS_USER_DETAILS: "PASSWORD_EQUALS_USER_DETAILS",
  NOT_SECURE_PASSWORD: "NOT_SECURE_PASSWORD",
  PASSWORD_ALREADY_USED: "PASSWORD_ALREADY_USED",
  USER_DISABLED: "USER_DISABLED",
  WRONG_ROLE: "WRONG_ROLE",
  PASSWORD_EXPIRED: "PASSWORD_EXPIRED",
};
