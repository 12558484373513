import React from "react";
import ReactDOM from "react-dom";
import "index.css";
import { App } from "App";
import Store from "store/store.js";
import { StoreProvider } from "store/storeUtils.js";
import { ApolloProvider } from "@apollo/client";
import Api from "gqlclient/GraphqlClient";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "styles/theme";
import { StylesProvider } from "@material-ui/core/styles";
import { injectContextHeaders } from "@tecma/logs-fe";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPage } from "@tecma/ds";
import { DeviceContext } from "@tecma/ds/lib/utils/context/device";
import * as serviceWorker from "./serviceWorker";

injectContextHeaders();
const store = new Store();

//TODO: CHANGE STORE NAME!
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ErrorBoundary
        FallbackComponent={() => {
          return (
            <ErrorPage
              logo={`${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(
                store.loginProjectName,
              )}/global/img/logo192.png`}
              language={
                store?.loggedUser
                  ? store.loggedUser.language + "-" + store.loggedUser.locale
                  : undefined
              }
            />
          );
        }}
      >
        <StoreProvider value={store}>
          <CssBaseline />
          <ApolloProvider client={Api(store)}>
            <StylesProvider injectFirst>
              <DeviceContext>
                <App />
              </DeviceContext>
            </StylesProvider>
          </ApolloProvider>
        </StoreProvider>
      </ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
