import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import Client from "gqlclient/Client";

const useValidateToken = (token, projectId) => {
  const [validatingToken, setValidatingToken] = useState(true);
  const [checkResetToken] = useMutation(Client.CHECK_RESET_TOKEN);
  const history = useHistory();

  useEffect(() => {
    checkResetToken(Client.CHECK_RESET_TOKEN_DEFAULT_OPTIONS(projectId, token)).then((res) => {
      if (
        res?.data?.checkResetToken === "invalidToken" ||
        res?.data?.checkResetToken === "expiredToken"
      ) {
        history.push("/forgotPassword", { from: "changePassword", error: "expiredToken" });
      }
      setValidatingToken(false);
    });
  }, []);

  return validatingToken;
};

export default useValidateToken;
