import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";

/**
 * This component is required because, as  per UX design, the tecma desktop_M breakpoint is divided: 1440x1024 and 1720x1117
 * So breakpoint has been shifted: desktop_M has lg and xl has breakpoint in Grid component
 */
const GridWithXXL = ({ xxl, ...props }) => {
  const xxlClass = `MuiGrid-grid-xxl-${xxl}`;
  return <Grid className={xxlClass} {...props} />;
};

GridWithXXL.propTypes = { xxl: PropTypes.number };
GridWithXXL.defaulProp = { xxl: 3 };

export default GridWithXXL;
