import React, { useState } from "react";
import { useStore } from "store/storeUtils";
import { observer } from "mobx-react";
import { LazyImage, Spinner, Button, LanguageSelector, Card } from "@tecma/ds";
import { useTranslation } from "@tecma/i18n";
import classNames from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import LogoTecma from "assets/images/tecma_logo.svg";
import Header from "./Header";
import Content from "./Content";
import Keyboard from "./Keyboard";

import "./login-wrapper.scss";

const LoginWrapper = observer(
  ({ children, className, keyboardProps, showLogo = true, loading, ...rest }) => {
    const store = useStore();
    const { i18n } = useTranslation();
    const matchesPhone = useMediaQuery("(max-width:1439px)", {
      noSsr: true,
    });

    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const [rotate, setRotate] = useState(false);
    const [showKeyboard, setShowKeyboard] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

    const onLanguageChange = (language) => {
      onLanguageSwitchToggle();
      setCurrentLanguage(language);
      i18n.changeLanguage(language);
    };

    const onLanguageSwitchToggle = () => {
      setIsDropDownOpen(!isDropDownOpen);
    };

    const classList = classNames("login-wrapper", { rotate }, className);

    const logo = `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(
      store.loginProjectName,
    )}/global/img/logo192.png`;

    return (
      <div className={classList} {...rest}>
        {store.loginProjectName && (
          <LazyImage.Image
            loadingElement={<Spinner />}
            className='login-wrapper-background'
            src={`${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${store.loginProjectName}/global/img/login.jpg`}
          />
        )}

        <div className='login-wrapper-container'>
          <Card
            className={classNames(
              "login-wrapper-content",
              loading && "login-wrapper-content-loading",
            )}
          >
            {loading ? <Spinner className='login-wrapper-spinner' /> : children}
            <div className='login-wrapper-container-footer'>
              {matchesPhone && (
                <LanguageSelector
                  currentLanguage={currentLanguage}
                  languages={store.configLanguages}
                  onChangeLanguage={onLanguageChange}
                  transparent
                  rotated={rotate}
                  position={{ vertical: "top", horizontal: "center" }}
                  transformOrigin={{ vertical: "bottom", horizontal: "center" }}
                />
              )}
              {showLogo && <LazyImage.Image src={logo} errorElement={" "} loadingElement={" "} />}
            </div>
          </Card>
        </div>

        <div className='login-wrapper-footer'>
          <div className='login-wrapper-footer-powered-by'>
            <span className='login-wrapper-footer-powered-by-text'>Powered by</span>
            <LazyImage.Image src={LogoTecma} />
          </div>
          <div className='login-wrapper-footer-right'>
            {!!keyboardProps && (
              <>
                <Button
                  className='login-wrapper-footer-right__desk-action'
                  onClick={() => setRotate(!rotate)}
                  color='inverse'
                  iconName='refresh'
                />
                <Button
                  disabled={!keyboardProps}
                  className={`login-wrapper-footer-right__desk-action ${
                    showKeyboard ? "keyboard-active" : ""
                  }`}
                  onClick={() => setShowKeyboard(!showKeyboard)}
                  color='inverse'
                  iconName='keyboard'
                />
              </>
            )}
            {!matchesPhone && (
              <LanguageSelector
                currentLanguage={currentLanguage}
                languages={store.configLanguages}
                onChangeLanguage={onLanguageChange}
                transparent
                rotated={rotate}
                position={{ vertical: "top", horizontal: "center" }}
                transformOrigin={{ vertical: "bottom", horizontal: "center" }}
              />
            )}
          </div>
        </div>

        {!!keyboardProps && showKeyboard && (
          <div className='login-wrapper-keyboard'>
            <Keyboard {...keyboardProps} />
          </div>
        )}
      </div>
    );
  },
);

const LoginWrapperSpace = Object.assign(LoginWrapper, {
  Header,
  Content,
});

export default LoginWrapperSpace;
