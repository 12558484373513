import React from "react";
import { observer } from "mobx-react";
import { useMutation } from "@apollo/client";
import Client from "gqlclient/Client";
import { useTranslation } from "@tecma/i18n";
import { useQuery } from "@apollo/client";
import { Spinner } from "@tecma/ds";

import Header from "components/Header/Header";
import { HeroContainer } from "components/HeroContainer";
import { ToolsContainer } from "components/ToolsContainer";
import { Footer } from "components/Footer";
import LoginOtp from "components/specific/LoginOtp";
import convertLanguage from "utils/convertLanguage";
import { useStore } from "../../store/storeUtils";

import "./home.scss";

/**
 * Home page
 * Contiene la lista dei prodotti
 * @component
 */

const Home = observer(() => {
  const [updateUser] = useMutation(Client.UPDATE_USER);
  const store = useStore();
  const { i18n, t } = useTranslation();
  const products = useQuery(
    Client.GET_ALL_PRODUCTS,
    Client.GET_ALL_PRODUCTS_DEFAULT_OPTIONS(store.project_id),
  );

  const projectToolStatus = useQuery(
    Client.GET_PROJECT_TOOL_STATUS,
    Client.GET_PROJECT_TOOL_STATUS_OPTIONS(store.project_id),
  );

  if (
    products &&
    !products.loading &&
    products.called &&
    products.data &&
    products.data.getAllProducts
  ) {
    if (
      projectToolStatus &&
      !projectToolStatus.loading &&
      projectToolStatus.called &&
      projectToolStatus.data &&
      projectToolStatus.data.getProjectToolStatus
    ) {
      store.setProducts(products.data.getAllProducts, projectToolStatus.data.getProjectToolStatus);
    } else {
      store.setProducts(products.data.getAllProducts);
    }
  }

  const setLang = async (lang, locale) => {
    const oldLanguage = store.loggedUser && store.loggedUser.language;
    const oldLocale = store.loggedUser && store.loggedUser.locale;
    const updateData = { ...store.loggedUser };
    updateData.language = lang;
    updateData.locale = locale;
    delete updateData.id;
    delete updateData.createdOn;
    delete updateData.__typename;
    updateData.project_ids = updateData.project_ids ? updateData.project_ids : [store.project_id];
    await updateUser(Client.UPDATE_USER_DEFAULT_OPTIONS(store.loggedUser.id, updateData))
      .then(() => {
        store.setSystemLanguage(lang);
        store.setLoggedUserLang(lang, locale);
        i18n.changeLanguage(convertLanguage(lang, locale));
      })
      .catch((e) => {
        if (oldLanguage) {
          store.setLoggedUserLang(oldLanguage, oldLocale);
          store.setSystemLanguage(oldLanguage);
          i18n.changeLanguage(convertLanguage(oldLanguage, oldLocale));
        }
        console.error(e);
        store.setSnackbarError(true, t("snackbar.failedOperation"));
      });
  };

  return (
    <div className='bss-home'>
      <Header setLang={setLang} />
      <div className='home-container'>
        <HeroContainer />
        {store.accountManagerEnabled && projectToolStatus?.data?.getProjectToolStatus ? (
          <ToolsContainer />
        ) : (
          <div className='home-spinner-container'>
            <Spinner size='large' type='dotted-circle' />
          </div>
        )}
        {store.loggedUser && store.modalOtp && <LoginOtp />}
      </div>
      <Footer />
    </div>
  );
});

export default Home;
