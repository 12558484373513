import { useReducer, useState } from "react";
import i18n from "@tecma/i18n";
import { useMutation } from "@apollo/client";
import { logger } from "@tecma/logs-fe";
import { useHistory } from "react-router-dom";

import Client from "gqlclient/Client";
import UserService from "api/User";
import { ACTIVATE_ACCOUNT_ERRORS, ENABLE_NEWSLETTER_POLICY } from "../constants";
import { useStore } from "store/storeUtils";
import { getErrorName } from "pages/utils";
import { ERROR_CODES } from "constants/errors";
import useValidateToken from "./useValidateToken";

const formReducer = (state, action) => {
  return {
    ...state,
    [action.type]: action.payload,
  };
};

const errorReducer = (state, action) => {
  return {
    ...state,
    [action.type]: action.payload,
  };
};

const useActivateAccount = (onSuccess) => {
  const store = useStore();
  const userService = new UserService(store.baseUrl);
  const history = useHistory();

  const [formValues, dispatchForm] = useReducer(formReducer, {
    newPassword: "",
    confirmPassword: "",
    subscription: null,
    privacyPolicy: null,
  });

  const [errorsState, dispatchError] = useReducer(errorReducer, {
    newPassword: null,
    confirmPassword: null,
    subscription: null,
    privacyPolicy: null,
    passwordIsValid: true,
  });

  const [formError, setFormError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const tokenParam = urlParams.get("token");
  const roleParam = urlParams.get("role");
  const validationCode = urlParams.get("code");
  const isEnabledNewsletter = roleParam && ENABLE_NEWSLETTER_POLICY.includes(roleParam);

  const [confirmUser] = useMutation(Client.CONFIRM_PASSWORD_USER);

  const validatingToken = useValidateToken(tokenParam, store.project_id);

  const isSubscriptionNotChosen = typeof formValues.subscription !== "boolean";

  const getIsDisabledPrivacy = () => {
    return isSubscriptionNotChosen || formValues.subscription === false;
  };

  const getIsDisabledSave = () => {
    return isLoading;
  };

  const needResetShowError = (field) => {
    if (errorsState[field]) {
      dispatchError({ type: field, payload: null });
    }
  };

  const handleOnChangeValue = (action, valid) => {
    if (isLoading) return;
    needResetShowError(action.type);
    if (typeof valid === "boolean") {
      if (valid !== errorsState.passwordIsValid) {
        dispatchError({ type: "passwordIsValid", payload: valid });
      }
    }
    if (action.type === "subscription" && action.payload === false) {
      dispatchForm({ type: "privacyPolicy", payload: false });
      dispatchError({ type: "privacyPolicy", payload: false });
    }
    if (action.type === "privacyPolicy") {
      dispatchForm({ type: "privacyPolicy", payload: !formValues.privacyPolicy });
    } else {
      dispatchForm(action);
    }
    const confirmPasswordMismatch =
      action.type === "confirmPassword" && formValues.newPassword !== action.payload;
    const newPasswordMismatch =
      action.type === "newPassword" &&
      formValues.confirmPassword &&
      formValues.confirmPassword !== action.payload;
    if (confirmPasswordMismatch || newPasswordMismatch) {
      const error = ACTIVATE_ACCOUNT_ERRORS["mismatchPassword"];
      dispatchError({
        type: error.field,
        payload: `businessplatform.resetPassword.error.${error.keyLabel}`,
      });
    } else {
      dispatchError({ type: "confirmPassword", payload: null });
    }
  };

  const saveSubscription = async () => {
    try {
      const subscriptionResponse = await userService.subscribeToNewsletterApi(
        tokenParam,
        store?.projectId,
        {
          projectHostKey: store.hostKey,
          privacyPolicy: formValues.privacyPolicy,
          subscription: formValues.subscription,
          projectArea: store.area === "sale" ? "sell" : "rent",
          projectDefaultLang: store.defaultLang ?? "en-GB",
        },
      );
      if (!subscriptionResponse.ok) {
        logger.error(`Subscription of user with ID: ${store?.loggedUser?.id} , failed`);
      }
    } catch (e) {
      logger.error(e);
    } finally {
      onSuccess();
    }
  };

  const checkEmptyFields = () => {
    let hasEmptyFields = false;
    const fieldToEscapeCheck = isEnabledNewsletter
      ? ["privacyPolicy"]
      : ["privacyPolicy", "subscription"];
    Object.keys(formValues).forEach((key) => {
      if (
        !fieldToEscapeCheck.includes(key) &&
        (formValues[key] === null || formValues[key] === "")
      ) {
        hasEmptyFields = true;
        dispatchError({
          type: key,
          payload: "businessplatform.resetPassword.field-required",
        });
      }
    });
    return hasEmptyFields;
  };

  const checkSubscriptionFields = () => {
    if (isEnabledNewsletter) {
      if (formValues.subscription === null) {
        dispatchError({
          type: "subscription",
          payload: "businessplatform.resetPassword.field-required",
        });
        return true;
      }
      if (formValues.subscription && !formValues.privacyPolicy) {
        dispatchError({
          type: "privacyPolicy",
          payload: "businessplatform.resetPassword.privacy-error",
        });
        return true;
      }
    }
    return false;
  };

  const handleResponse = async (response) => {
    if (response?.data?.confirmUserAndResetPassword) {
      if (isEnabledNewsletter && typeof formValues.subscription === "boolean") {
        await saveSubscription();
      } else {
        onSuccess();
      }
    } else {
      setFormError("businessplatform.passwordError");
    }
  };

  const handleError = (error) => {
    const errorName = getErrorName(error);
    switch (errorName) {
      case ERROR_CODES.WRONG_PASSWORD:
      case ERROR_CODES.INVALID_TOKEN:
      case ERROR_CODES.EXPIRED_TOKEN: {
        history.push("/forgotPassword", { from: "activateAccount", error: "expiredToken" });
        break;
      }
      case ERROR_CODES.PASSWORD_EQUALS_USER_DETAILS:
      case ERROR_CODES.NOT_SECURE_PASSWORD:
      case ERROR_CODES.PASSWORD_ALREADY_USED: {
        setFormError("businessplatform.passwordNotOk");
        break;
      }
      default: {
        setFormError("businessplatform.passwordError");
        break;
      }
    }
  };

  const handleOnConfirmAccount = async () => {
    if (isLoading) return;
    setFormError(null);
    const hasSubscriptionError = checkSubscriptionFields();
    const hasEmptyFields = checkEmptyFields();
    if (!hasEmptyFields && !hasSubscriptionError && errorsState.passwordIsValid) {
      if (formValues.newPassword !== formValues.confirmPassword) {
        const error = ACTIVATE_ACCOUNT_ERRORS["mismatchPassword"];
        dispatchError({
          type: error.field,
          payload: `businessplatform.resetPassword.error.${error.keyLabel}`,
        });
        return;
      }
      setIsLoading(true);
      try {
        const response = await confirmUser(
          Client.CONFIRM_PASSWORD_USER_DEFAULT_OPTIONS(
            store.project_id,
            tokenParam,
            validationCode,
            formValues.newPassword,
          ),
        );
        await handleResponse(response);
      } catch (error) {
        logger.error(error);
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getPrivacyDocumentHref = () => {
    const currentLanguage = i18n.language?.split("-")?.[0] === "it" ? "it" : "en";
    const documentUrl = `${process.env.REACT_APP_BUCKET_BASEURL}/businessplatform/pdf/newsletterPrivacyPolicy/${currentLanguage}/Privacy Policy Newsletter.pdf`;
    return documentUrl;
  };

  return {
    formValues,
    errorsState,
    formError,
    isLoading,
    isEnabledNewsletter,
    isDisabledConfirmAction: getIsDisabledSave(),
    isDisabledPrivacy: getIsDisabledPrivacy(),
    privacyDocumentHref: getPrivacyDocumentHref(),
    onCompleteRegistration: handleOnConfirmAccount,
    handleOnChangeValue,
    validatingToken,
  };
};

export default useActivateAccount;
