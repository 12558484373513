import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import CrmInput from "components/generic/CrmInput";
import CrmButton from "components/generic/CrmButton";
import CrmSimpleModal from "components/generic/CrmSimpleModal";
import CrmLoader from "components/generic/CrmLoader";

import { useMutation } from "@apollo/client";
import Client from "gqlclient/Client";
import { useStore } from "store/storeUtils";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";

import "styles/manager-activation.scss";

/**
 * Pagina di registrazione utenza manager
 * @component
 */
const ManagerActivation = observer(() => {
  const store = useStore();
  const history = useHistory();

  const [retry, setRetry] = React.useState(false);
  const [activateAccount, accountManagerData] = useMutation(Client.MANAGER_ACTIVATION);

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});

  const nameValidation = (fieldValue) => {
    if (fieldValue.trim() === "") {
      return false;
    }
    return true;
  };

  const emailValidation = (email) => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
        email,
      )
    ) {
      return true;
    }
    if (email.trim() === "") {
      return false;
    }
    return false;
  };

  const passwordValidation = (fieldName, fieldValue) => {
    let tempObject = { password: false, confirmPassword: false };

    tempObject[fieldName] = fieldValue.trim().length >= 6;

    if (fieldName === "password") {
      tempObject.confirmPassword = user.confirmPassword
        ? user.confirmPassword === fieldValue
        : false;
    }
    if (fieldName === "confirmPassword") tempObject.confirmPassword = user.password === fieldValue;

    return tempObject;
  };

  const validate = {
    firstName: (name) => nameValidation(name),
    lastName: (name) => nameValidation(name),
    email: emailValidation,
    password: (value) => passwordValidation("password", value).password,
    confirmPassword: (value) => passwordValidation("confirmPassword", value).confirmPassword,
  };

  const handleChange = (evt) => {
    const { id, value } = evt.target;

    setUser({
      ...user,
      [id]: value,
    });

    setTouched({
      ...touched,
      [id]: true,
    });

    // eslint-disable-next-line no-unused-vars
    const { [id]: removedError, ...rest } = errors;

    let error;
    if (id === "password" || id === "confirmPassword") {
      if (id === "password") {
        error = [!validate["password"](value), !validate["confirmPassword"](user.confirmPassword)];
        setErrors({
          ...rest,
          password: error[0],
          confirmPassword: error[1],
        });
      }
      if (id === "confirmPassword") {
        error = [!validate["confirmPassword"](value), !validate["password"](user.password)];
        setErrors({
          ...rest,
          password: error[1],
          confirmPassword: error[0],
        });
      }
    } else {
      error = !validate[id](value);
      setErrors({
        ...rest,
        ...(error && { [id]: touched[id] && error }),
      });
    }
  };

  const validInput = () => {
    if (
      (!Object.values(errors).length ||
        !Object.values(errors).filter((er) => er === true).length) && // errors object is empty
      Object.values(touched).length === Object.values(user).length && // all fields were touched
      Object.values(touched).every((t) => t === true) // every touched field is true
    ) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    if (
      doneCalling &&
      accountManagerData.data &&
      accountManagerData.data.createAccountManagerUsers
    ) {
      accountActivated();
    }
  });

  const doneCalling =
    accountManagerData && accountManagerData.called && !accountManagerData.loading;

  const accountActivated = () => {
    store.setAccountManagerEnabled(true);
    history.push("/login");
  };

  if (accountManagerData && accountManagerData.called && accountManagerData.loading)
    return <CrmLoader z loading />;

  if (
    doneCalling &&
    (!accountManagerData.data || !accountManagerData.data.createAccountManagerUsers) &&
    !store.errorModal &&
    !retry
  ) {
    store.setErrorModal(true);
  }

  const fields = [
    {
      name: "firstName",
      label: "Nome",
      type: "text",
      error: errors.firstName,
      value: user.firstName,
    },
    {
      name: "lastName",
      label: "Cognome",
      type: "text",
      error: errors.lastName,
      value: user.lastName,
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      error: errors.email,
      value: user.email,
      helperText: !(user.email === "" || !errors.email) ? "Formato mail non valido" : "",
    },
    {
      name: "password",
      label: "Password",
      type: "password",
      error: errors.password,
      value: user.password,
      helperText:
        user.password !== "" && user.password.length < 6
          ? "La password deve contenere almeno 6 caratteri"
          : "",
    },
    {
      name: "confirmPassword",
      label: "Conferma password",
      type: "password",
      error: errors.confirmPassword,
      value: user.confirmPassword,
      helperText: user.password !== user.confirmPassword ? "Le password non corrispondono" : "",
    },
  ];

  return (
    <div>
      <CrmSimpleModal
        open={store.errorModal}
        title={"Errore"}
        onClose={() => {
          store.setErrorModal(false);
          setRetry(true);
        }}
      >
        Errore nella registrazione
      </CrmSimpleModal>
      <Grid container>
        <Hidden xsDown>
          <Grid item md={6}>
            {store.assetLogin && store.assetLogin.length > 0 ? (
              <img
                alt='registration'
                className='left-half-registration'
                src={store.assetLogin[0]}
              />
            ) : (
              ""
            )}
          </Grid>
        </Hidden>
        <Grid item xs={12} md={6}>
          <div className='right-half-registration'>
            {store.logo && store.logo.length > 0 ? (
              <img className='registration-icon' alt='logo' src={store.logo[0]} />
            ) : (
              ""
            )}
            <div className='registration-hint'>Inserisci i dati per registrarti</div>

            <div className='inputWrapper'>
              {fields.map((f, i) => (
                <CrmInput
                  key={i}
                  id={f.name}
                  variant='standard'
                  label={f.label}
                  type={f.type}
                  extraClass='registration-margin-bottom'
                  defaultInput
                  error={f.error}
                  value={f.value}
                  onChange={handleChange}
                  helperText={f.helperText}
                />
              ))}
            </div>

            <CrmButton
              color='primary'
              disabled={!validInput()}
              extraClass='registration-button'
              onClick={() => {
                setRetry(false);

                activateAccount(Client.MANAGER_ACTIVATION_DEFAULT_OPTIONS(store.project_id, user));
              }}
            >
              Registrati
            </CrmButton>
            {store.vendorLogo && store.vendorLogo.length ? (
              <img
                className='registration-logo-vendor'
                alt='logo vendor'
                src={store.vendorLogo[0]}
              />
            ) : (
              ""
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
});

export default ManagerActivation;
