import React from "react";
import Grid from "@material-ui/core/Grid";
import { ToolBox } from "components/ToolBox";
import { useStore } from "store/storeUtils";
import { observer } from "mobx-react";
import { useTranslation } from "@tecma/i18n";
import filterToolsAndProducts from "utils/FilterToolsAndProducts";
import { GridWithXXL } from "components/GridWithXXL";

import "./toolsContainer.scss";

const ToolsContainer = observer(() => {
  const store = useStore();

  const [toolsMap, setToolsMap] = React.useState([]);
  const { t } = useTranslation();
  const retrieveTools = () => {
    return store.products.filter((prod) => {
      const tool = store.enabledTools.find((t) => t.name === prod.name);
      let isVisible = false;
      // se prodotto è default e abbiamo configurato l'enabledTool con enabled= true o undefined
      if (prod.default === true && tool && tool.enabled !== false) isVisible = true;
      // se prodotto è default e NON abbiamo configurato l'enabledTool
      else if (prod.default === true && !tool) isVisible = true;
      // se prodotto NON è default ed abbiamo configurato l'enabledTool con enabled= true o undefined
      else if (tool && tool.enabled !== false) isVisible = true;
      // altrimenti, se il prodotto NON è default e NON è configurato, restituiamo false
      return isVisible;
    });
  };

  const showToolBox = (tool) => {
    const hasRoles = store.loggedUser && tool.roles && tool.roles.length > 0;
    return hasRoles
      ? tool.roles.filter((role) => role === store.loggedUser.role).length !== 0
      : store.loggedUser
      ? true
      : false;
  };

  React.useEffect(() => {
    setToolsMap(retrieveTools());
    // eslint-disable-next-line
  }, []);

  // Reperiamo la lista dei prodotti da mostrare da enabledTools
  // Visualizzo tutti i prodotti che hanno impostato (default == true || (enabledTools[x].enabled || enabledTools[x].enabled === undefined ))
  return (
    <>
      {store.products && (
        <Grid
          container
          spacing={3}
          data-testid='tools-container-grid'
          className='bss-tools-container'
        >
          {toolsMap
            .filter((tool) => showToolBox(tool))
            .map((tool, i) => {
              const selectedTool = store.enabledTools.filter((t) =>
                filterToolsAndProducts(t, tool),
              )[0];
              const toolboxContent = {
                name: tool.name,
                version: selectedTool ? selectedTool.version : "",
                title: t(tool.title),
                description: t(tool.label),
                url: selectedTool ? selectedTool.url : "",
                status: tool && tool.status ? tool.status : undefined,
              };
              return (
                <GridWithXXL key={i} item xs={12} sm={6} md={4} lg={4} xl={3} xxl={3}>
                  <ToolBox tool={toolboxContent} />
                </GridWithXXL>
              );
            })}
        </Grid>
      )}
    </>
  );
});

export default ToolsContainer;
