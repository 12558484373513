import Cookies from "js-cookie";
import { logger } from "@tecma/logs-fe";
import psl from "psl";

export const logout = (history) => {
  try {
    let domain = "localhost";
    if (window.location.hostname !== "localhost") {
      const pslUrl = psl.parse(window.location.hostname);
      domain = pslUrl.domain;
    }

    Cookies.remove("jwt", { domain });
    Cookies.remove("refreshToken", { domain });
    Cookies.remove("expiresIn", { domain });
    Cookies.remove("vendorId", { domain });
  } catch (e) {
    logger.error("Error during logout operations..", e);
  }
  if (history) {
    history.push("/login");
  }
};
