import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "@tecma/i18n";
import { useHistory } from "react-router-dom";
import { Button, Icon } from "@tecma/ds";

import { LoginWrapper } from "components/LoginWrapper";

const EmailSent = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();

  const goToLogin = () => {
    history.push("/login");
  };

  return (
    <LoginWrapper className='forgot-password'>
      <LoginWrapper.Header>
        <Icon iconName='check-circle' filled />
        <span className='login-wrapper-header-title'>{t("businessplatform.emailSent")}</span>
      </LoginWrapper.Header>
      <LoginWrapper.Content>
        <span className='login-wrapper-content-text'>
          {t("businessplatform.emailSent.description")}
        </span>
        <Button onClick={goToLogin} fluid>
          {t("businessplatform.emailSent.goToLogin")}
        </Button>
      </LoginWrapper.Content>
    </LoginWrapper>
  );
});

export default EmailSent;
