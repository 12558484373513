import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
const breakpointValues = {
  xs: 0,
  sm: 768,
  md: 1024,
  lg: 1440,
  xl: 1720,
  xxl: 1920,
};
// A custom theme for this app
const theme = createMuiTheme({
  breakpoints: { values: breakpointValues, keys: ["xs", "sm", "md", "lg", "xl", "xxl"] },
  palette: {
    type: "light",
    primary: {
      main: "#f3f3f3", // '#61dafb',
      light: "#61dafb",
      dark: "#52575D",
    },
    secondary: {
      main: "#b5ecfb",
      light: "#61dafb",
      dark: "#21a1c4",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff", // '#282c34',
    },
    progress: {
      main: "#000",
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        padding: "20px 10px",
        margin: "10px",
        backgroundColor: "#fff", // 5d737e
      },
    },
    MuiButton: {
      root: {
        margin: "5px",
        fontWeight: "700",
      },
      containedPrimary: {
        color: "#fff",
        backgroundColor: "#000",
        "&:hover": {
          backgroundColor: "#000",
        },
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: "auto",
      },
    },
    MuiGrid: {
      container: {
        justifyContent: "center",
      },
    },
    MuiInput: {
      // underline: {
      //   "&:before": {
      //     borderBottom: "1px solid #E8E9EB !important"
      //   },
      //   "&$focused": {
      //     borderBottom: "2px solid #E8E9EB !important"
      //   },
      //   "&:after": {
      //     borderBottom: "2px solid #E8E9EB"
      //   }
      // }
    },
    MuiInputLabel: {
      root: {
        color: "#989898",
        "&$focused": {
          color: "#989898",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        "&$error": {
          marginTop: "7px !important",
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "12px",
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        "&$checked": {
          color: "#1E201F",
        },
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: "0 9px",
      },
    },
    MuiIconButton: {
      root: {
        color: "#1E201F",
      },
    },
  },
});
export default theme;
