import React from "react";
import { Link } from "react-router-dom";
import { useStore } from "store/storeUtils";
import { observer } from "mobx-react";
import { useTranslation } from "@tecma/i18n";

/* import "styles/hero-container.scss"; */
import "./heroContainer.scss";

const HeroContainer = observer(() => {
  const store = useStore();
  const { t } = useTranslation();
  const heroLogo = `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${
    store.loginProjectName
  }/businessplatform/hero-logo.svg?v=${new Date().getDay()}`;
  const heroImage = `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${
    store.loginProjectName
  }/businessplatform/hero-image.jpg?v=${new Date().getDay()}`;

  return (
    <div className='heroContainer-wrapper'>
      <div
        className='heroContainer-img'
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url('${heroImage}')`,
        }}
      >
        <div className='heroContainer-logo' style={{ backgroundImage: `url('${heroLogo})'` }} />
        <p className='heroContainer-par'>{t("businessplatform.description")}</p>
        {!store.accountManagerEnabled && (
          <div className='button-wrapper'>
            <Link to='/registration' className='button-login'>
              {t("businessplatform.userActivation")}
            </Link>
          </div>
        )}
      </div>
      <div className='heroContainer-title-wrapper'>
        <h5 className='heroContainer-title'>{t("businessplatform.cloudEfficiency")}</h5>
        <div className='hereContainer-subtitle-wrapper'>
          {store.loggedUser && (
            <p>
              {t("businessplatform.hello")},&nbsp;{store.loggedUser.firstName}!
            </p>
          )}
          <p>{t("businessplatform.yourBusinessSuite")}</p>
        </div>
      </div>
    </div>
  );
});

export default HeroContainer;
