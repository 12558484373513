import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import Client from "gqlclient/Client";
import { useStore } from "store/storeUtils";
import { useHistory, Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "@tecma/i18n";
import { Button, Input, Spinner } from "@tecma/ds";
import { getValidatorsPass } from "@tecma/ds/lib/components/Input/Input";
import { logger } from "@tecma/logs-fe";

import { getParamByName } from "utils/urlParamUtils";
import { LoginWrapper } from "components/LoginWrapper";
import { getPasswordValidators, getErrorName } from "pages/utils";
import { ERROR_CODES } from "constants/errors";
import useValidateToken from "hooks/useValidateToken";

/**
 * Pagina di change password
 * @component
 */
const ChangePasswordContent = observer(({ onSuccess }) => {
  const store = useStore();
  const history = useHistory();

  const { t } = useTranslation();

  const secretToken = getParamByName("token");

  const initialValues = { password: "", confirmPassword: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [userInteractedWithEmail, setUserInteractedWithEmail] = useState({});
  const [formError, setFormError] = useState(null);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const [changePassword] = useMutation(Client.CHANGE_PASSWORD);

  const validators = getPasswordValidators(t);

  const validatorPass = getValidatorsPass(formValues.password, validators);

  const validatingToken = useValidateToken(secretToken, store.project_id);

  if (!secretToken) {
    return <Redirect exact to='/login' />;
  }

  const handleSubmit = (e) => {
    if (e) {
      // this function is used in the Keyboard component as onSubmit and doesn't need the event props
      e.preventDefault();
    }
    const { password } = formValues;
    if (!userInteractedWithEmail.password || userInteractedWithEmail.confirmPassword) {
      setUserInteractedWithEmail({
        password: true,
        confirmPassword: true,
      });
    }
    if (
      !isPasswordValid ||
      validateField("password", true) ||
      validateField("confirmPassword", true)
    ) {
      console.warn("Cannot submit");
      return;
    }
    setLoading(true);
    changePassword(Client.CHANGE_PASSWORD_DEFAULT_OPTIONS(store.project_id, secretToken, password))
      .then((res) => {
        if (res.data.resetPassword) {
          onSuccess();
        } else {
          setFormError("businessplatform.passwordError");
        }
      })
      .catch((error) => {
        logger.error(error);
        const errorName = getErrorName(error);
        switch (errorName) {
          case ERROR_CODES.WRONG_PASSWORD:
          case ERROR_CODES.INVALID_TOKEN:
          case ERROR_CODES.EXPIRED_TOKEN: {
            history.push("/forgotPassword", { from: "changePassword", error: "expiredToken" });
            break;
          }
          case ERROR_CODES.USER_DISABLED: {
            history.push("/login", { from: "changePassword", error: "userDisabled" });
            break;
          }
          case ERROR_CODES.PASSWORD_EQUALS_USER_DETAILS:
          case ERROR_CODES.NOT_SECURE_PASSWORD:
          case ERROR_CODES.PASSWORD_ALREADY_USED: {
            setFormError("businessplatform.passwordNotOk");
            break;
          }
          default: {
            setFormError("businessplatform.passwordError");
            break;
          }
        }
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const validatorPass = getValidatorsPass(value, validators);
    const allValidatorsOk = validatorPass ? Object.values(validatorPass).every((val) => val) : null;
    if (!userInteractedWithEmail[name]) {
      setUserInteractedWithEmail({ ...userInteractedWithEmail, [name]: true });
    }
    if (name === "password") {
      setIsPasswordValid(allValidatorsOk);
    }
    setFormValues({ ...formValues, [name]: value });
  };

  const validateField = (field, submitCheck) => {
    if (userInteractedWithEmail[field] || submitCheck) {
      if (!formValues[field]?.trim()) {
        return t("businessplatform.resetPassword.field-required");
      }
      if (field === "confirmPassword" && formValues.password !== formValues.confirmPassword) {
        return t("businessplatform.passwordNotMatch");
      }
    }
  };

  return (
    <LoginWrapper className='change-password' loading={validatingToken}>
      <LoginWrapper.Header title='businessplatform.changePassword' />
      <LoginWrapper.Content
        onSubmit={handleSubmit}
        showAlert={!!formError}
        alertProps={{ title: formError }}
      >
        <Input
          className='login-wrapper-content-input'
          value={formValues.password}
          label={t("businessplatform.resetPassword.createPassword")}
          name='password'
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={() => {
            !userInteractedWithEmail.password &&
              setUserInteractedWithEmail({ ...userInteractedWithEmail, password: true });
          }}
          status={!!validateField("password") && "error"}
          helpText={validateField("password")}
          validators={{
            upperAndLower: t("businessplatform.resetPassword.upperAndLowerCriteria"),
            atLeastANumber: t("businessplatform.resetPassword.numberCriteria"),
            atLeastASymbol: t("businessplatform.resetPassword.symbolCriteria"),
            minimumMaximumLength: t("businessplatform.resetPassword.sevenCharacterCriteria"),
          }}
          validatorResults={validatorPass}
          type='password'
          placeholder={t("businessplatform.resetPassword.placeholder.createPassword")}
        />
        <Input
          className='login-wrapper-content-input'
          name='confirmPassword'
          value={formValues.confirmPassword}
          label={t("businessplatform.resetPassword.repeatPassword")}
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={() => {
            !userInteractedWithEmail.confirmPassword &&
              setUserInteractedWithEmail({ ...userInteractedWithEmail, confirmPassword: true });
          }}
          status={!!validateField("confirmPassword") && "error"}
          helpText={validateField("confirmPassword")}
          type='password'
          placeholder={t("businessplatform.resetPassword.placeholder.repeatPassword")}
        />
        <Button fluid type='submit'>
          {loading && <Spinner />}
          {t("businessplatform.resetPassword.createPassword")}
        </Button>
      </LoginWrapper.Content>
    </LoginWrapper>
  );
});

export default ChangePasswordContent;
