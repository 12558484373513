import { gql } from "@apollo/client";
// import { pollInterval } from "gqlclient/ClientConstants";

const PROJECT_DATA_TO_RETRIEVE = `
      id
      name
      logo
      vendorLogo
      assetLogin
      hostKey
      assetKey
      pdf
      assetsUrl
      displayName
      pageTitles {
        followup
        tuning
      }
      enabledTools
      accountManagerEnabled
      businessPlatformConfig
      baseurl
      area
      defaultLang
`;

const USER_DATA_TO_RETRIEVE = `
      id
      firstName
      lastName
      email
      project_ids
      role
      firstLogin
      language
      locale
      TwoFA
`;

const USER_INFO_TO_RETRIEVE = `
      id
      firstName
      lastName
      email
      project_ids
      role
      language
      locale
      firstLogin
      TwoFA
`;

const PRODUCT_DATA_TO_RETRIEVE = `
    name
    label
    title
    default
    roles
`;

const GET_ALL_PRODUCTS = gql`
query getAllProducts($project_id :ID!){
  getAllProducts(project_id :$project_id){
    ${PRODUCT_DATA_TO_RETRIEVE}
  }
}`;

const GET_ALL_PRODUCTS_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
  };
};

const GET_PROJECT_INFO = gql`
  query getProjectInfoByHost ($host: String!, $url: String) {
    getProjectInfoByHost (host: $host, url: $url) {
      ${PROJECT_DATA_TO_RETRIEVE}
    }
  }`;

const GET_PROJECT_INFO_DEFAULT_OPTIONS = (hostname) => {
  return {
    variables: {
      host: hostname,
      url: window.location.href,
    },
  };
};

const LOGIN = gql`
  mutation loginTecma($input: LoginByProjectInput!) {
    loginByProjectBP(input: $input) {
      token {
        accessToken
        refreshToken
        tokenType
        expiresIn
        hostKey
        expiredToken
      }
      user {
        id
        firstName
        lastName
        createdOn
        email
        role
        language
        TwoFA
        firstLogin
      }
      otpValidationKey {
        validationKey
        expirationDate
      }
      isPCTuser
    }
  }
`;

const LOGIN_DEFAULT_OPTIONS = (email, password, project_id) => {
  return {
    variables: {
      email: email,
      password: password,
      project_id: project_id,
    },
  };
};

const LOGIN_OTP = gql`
  mutation loginTecma($input: LoginByProjectInput!) {
    loginByProjectOTP(input: $input) {
      token {
        accessToken
        refreshToken
        tokenType
        expiresIn
        hostKey
        expiredToken
      }
      user {
        id
        firstName
        lastName
        createdOn
        email
        role
        language
        TwoFA
        firstLogin
      }
      isPCTuser
    }
  }
`;

const LOGIN_OTP_DEFAULT_OPTIONS = (email, password, project_id, otp, otpValidationKey) => {
  return {
    variables: {
      email: email,
      password: password,
      project_id: project_id,
      otp: otp,
      otpValidationKey: otpValidationKey,
    },
  };
};

const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

const MANAGER_ACTIVATION = gql`
  mutation createAccountManagerUsers($project_id: ID, $input: UserInput!) {
    createAccountManagerUsers(project_id: $project_id, input: $input) {
      id
      firstName
      lastName
      email
      createdOn
      client
      project_ids
    }
  }
`;

const MANAGER_ACTIVATION_DEFAULT_OPTIONS = (project_id, input) => {
  return {
    variables: {
      project_id,
      input: {
        firstName: input.firstName,
        lastName: input.lastName,
        password: input.password,
        email: input.email,
      },
    },
  };
};

const ASK_CHANGE_PASSWORD = gql`
  mutation askChangePassword($project_id: ID!, $email: String!, $template_type: String) {
    askChangePasswordBP(project_id: $project_id, email: $email, template_type: $template_type)
  }
`;

const ASK_CHANGE_PASSWORD_DEFAULT_OPTIONS = (project_id, email, template_type) => {
  return {
    variables: {
      email,
      project_id,
      template_type,
    },
  };
};

const CHANGE_PASSWORD = gql`
  mutation changePassword($project_id: ID!, $token: String!, $password: String!) {
    resetPassword(project_id: $project_id, token: $token, password: $password)
  }
`;

const CHANGE_PASSWORD_DEFAULT_OPTIONS = (project_id, token, password) => {
  return {
    variables: {
      token,
      password,
      project_id,
    },
  };
};

/* // esample with policies
const GET_ALL_APPARTMENTS_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
    pollInterval,
    fetchPolicy: "cache-and-network",
  };
};
*/

const GENERATE_OTP = gql`
  mutation generateOTP($project_id: ID!, $input: OTPReceive!) {
    generateOTP(project_id: $project_id, input: $input) {
      validationKey
      expirationDate
    }
  }
`;

const GENERATE_OTP_DEFAULT_OPTIONS = (project_id, where, phone = false) => {
  return {
    variables: {
      project_id,
      input: phone ? { phone: where } : { email: where },
    },
  };
};

const UPDATE_FIRSTLOGIN = gql`
  mutation updateFirstLogin($project_id: ID!, $id: ID!, $value: Boolean!) {
    updateFirstLogin(project_id: $project_id, id: $id, value: $value) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;

const UPDATE_FIRSTLOGIN_DEFAULT_OPTIONS = (project_id, id, value) => {
  return {
    variables: {
      project_id,
      id,
      value,
    },
  };
};

const UPDATE_TwoFA = gql`
  mutation updateTwoFA($project_id: ID!, $id: ID!, $value: Boolean!) {
    updateTwoFA(project_id: $project_id, id: $id, value: $value) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;
const UPDATE_TwoFA_DEFAULT_OPTIONS = (project_id, id, value) => {
  return {
    variables: {
      project_id,
      id,
      value,
    },
  };
};

const UPDATE_USER = gql`
  mutation updateUsers( $id: ID!, $input: UserInput!) {
    updateUsers( id: $id, input: $input) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;
const UPDATE_USER_DEFAULT_OPTIONS = (id, input) => {
  return {
    variables: {
      id: id,
      input: input,
    },
  };
};

const GET_USER_INFO = gql`
  query getUserByJWT($project_id: ID!) {
    getUserByJWT(project_id: $project_id) {
      ${USER_INFO_TO_RETRIEVE}
    }
  }
`;

const GET_PROJECT_TOOL_STATUS = gql`
  query getProjectToolStatus($project_id: ID!) {
    getProjectToolStatus(id: $project_id) {
      status
      toolName
    }
  }
`;

const GET_USER_INFO_DEFAULT_OPTIONS = (pId, skipCondition) => {
  return {
    variables: {
      project_id: pId,
    },
    skip: skipCondition,
    fetchPolicy: "network-only",
  };
};

const GET_PROJECT_TOOL_STATUS_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
  };
};

const CONFIRM_PASSWORD_USER = gql`
  mutation confirmUserAndChangePassword(
    $project_id: ID!
    $token: String!
    $password: String!
    $newPassword: String!
  ) {
    confirmUserAndResetPassword(
      project_id: $project_id
      token: $token
      password: $password
      newPassword: $newPassword
    )
  }
`;

const CONFIRM_PASSWORD_USER_DEFAULT_OPTIONS = (project_id, token, password, newPassword) => {
  return {
    variables: {
      token,
      password,
      newPassword,
      project_id,
    },
  };
};

const CHECK_RESET_TOKEN = gql`
  mutation checkResetToken($project_id: ID!, $token: String!) {
    checkResetToken(project_id: $project_id, token: $token)
  }
`;

const CHECK_RESET_TOKEN_DEFAULT_OPTIONS = (project_id, token) => {
  return {
    variables: {
      project_id,
      token,
    },
  };
};

const Client = {
  GET_PROJECT_INFO,
  GET_PROJECT_INFO_DEFAULT_OPTIONS,
  LOGIN,
  LOGIN_DEFAULT_OPTIONS,
  LOGOUT,
  MANAGER_ACTIVATION,
  MANAGER_ACTIVATION_DEFAULT_OPTIONS,
  ASK_CHANGE_PASSWORD,
  ASK_CHANGE_PASSWORD_DEFAULT_OPTIONS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_DEFAULT_OPTIONS,
  GENERATE_OTP,
  GENERATE_OTP_DEFAULT_OPTIONS,
  LOGIN_OTP,
  LOGIN_OTP_DEFAULT_OPTIONS,
  UPDATE_FIRSTLOGIN,
  UPDATE_FIRSTLOGIN_DEFAULT_OPTIONS,
  UPDATE_TwoFA,
  UPDATE_TwoFA_DEFAULT_OPTIONS,
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_DEFAULT_OPTIONS,
  UPDATE_USER,
  UPDATE_USER_DEFAULT_OPTIONS,
  GET_USER_INFO,
  GET_USER_INFO_DEFAULT_OPTIONS,
  GET_PROJECT_TOOL_STATUS,
  GET_PROJECT_TOOL_STATUS_OPTIONS,
  CONFIRM_PASSWORD_USER,
  CONFIRM_PASSWORD_USER_DEFAULT_OPTIONS,
  CHECK_RESET_TOKEN,
  CHECK_RESET_TOKEN_DEFAULT_OPTIONS,
};

export default Client;
