import { useEffect, useReducer, useState } from "react";

import { createTicket, getJiraFieldOptions } from "gqlclient/rent";
import {
  generateJIRAFieldOptions,
  generateIssueTypeOptions,
} from "utils/generateSelectValuesLabel";
import { useStore } from "store/storeUtils";
import {
  MAX_SUBJECT_LENGTH,
  MAX_DESCRIPTION_LENGTH,
  SUPPORT_FORMS_STATE,
  SUPPORT_ERRORS_STATE,
} from "constants/SupportFormConstants";

const optionsReducer = (state, action) => {
  return {
    ...state,
    [action.type]: action.payload,
  };
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return SUPPORT_FORMS_STATE;
    default:
      return {
        ...state,
        [action.type]: action.payload,
      };
  }
};

const errorReducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return SUPPORT_ERRORS_STATE;
    default:
      return {
        ...state,
        [action.type]: action.payload,
      };
  }
};

const useSupport = (isOpen) => {
  const store = useStore();
  const [jiraKey, setJiraKey] = useState("");
  const [alertType, setAlertType] = useState("warning");
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [options, dispatchOptions] = useReducer(optionsReducer, {
    issues: generateIssueTypeOptions(),
    products: [],
    devices: [],
    browsers: [],
  });

  const [formValues, dispatchForm] = useReducer(formReducer, SUPPORT_FORMS_STATE);

  const [errorsState, dispatchError] = useReducer(errorReducer, SUPPORT_ERRORS_STATE);

  const resetAllReducers = () => {
    dispatchForm({ type: "reset" });
    dispatchError({ type: "reset" });
  };

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      const inputField = {
        projectId: store.projectId,
      };
      Promise.all([
        getJiraFieldOptions({ ...inputField, name: "PRODUCTS" }),
        getJiraFieldOptions({ ...inputField, name: "DEVICE" }),
        getJiraFieldOptions({ ...inputField, name: "BROWSER" }),
      ])
        .then(([products, devices, browsers]) => {
          const productsTranslated = generateJIRAFieldOptions(products, "product");
          const devicesTranslated = generateJIRAFieldOptions(devices, "device");
          const browsersTranslated = generateJIRAFieldOptions(browsers, "browser");
          dispatchOptions({ type: "products", payload: productsTranslated });
          dispatchOptions({ type: "devices", payload: devicesTranslated });
          dispatchOptions({ type: "browsers", payload: browsersTranslated });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      resetAllReducers();
    }
  }, [isOpen]);

  const validationForms = {
    subject: formValues.subject.length > MAX_SUBJECT_LENGTH || formValues.subject.length === 0,
    description:
      formValues.description.length > MAX_DESCRIPTION_LENGTH || formValues.description.length === 0,
    issues: formValues.issues.length === 0,
    devices: formValues.devices.length === 0,
    products: formValues.products.length === 0,
    browsers: formValues.browsers.length === 0,
  };

  const getIsDisabledSave = () => {
    return isLoading;
  };

  const handleOnBlur = (value) => {
    const finalName = typeof value === "string" ? value : value?.target?.name;
    dispatchError({
      type: finalName,
      payload: validationForms[finalName],
    });
  };

  const handleOnChangeValue = (name, value) => {
    handleOnBlur(name);
    dispatchForm({ type: name, payload: value });
    dispatchError({
      type: name,
      payload: false,
    });
  };

  const handleOnCreateTicket = async () => {
    const products = formValues.products.map((product) => product.value);
    const browsers = formValues.browsers.map((browser) => browser.value);
    const devices = formValues.devices.map((device) => device.value);

    const summary = `${store?.area === "rent" ? "RENT" : "SELL"} - ${formValues.subject}`;

    const inputTicket = {
      type: formValues.issues.type,
      summary: summary,
      description: formValues.description,
      projectId: store.projectId,
      requestType: formValues.issues.label,
      products: products,
      environment: {
        browsers: browsers,
        devices: devices,
      },
    };

    const response = await createTicket(inputTicket);

    if (response?.done === true) {
      setJiraKey(response.jiraKey);
      setAlertType("success");
    }
    if (response?.done === false) setAlertType("error");

    if (response?.message) {
      setAlertType("error");
    }
    setShowAlert(true);
  };

  const handleOnShowAlert = () => {
    setShowAlert(true);
  };

  const handleOnCancel = () => {
    setShowAlert(false);
  };

  const closeShowAlert = () => {
    if (alertType === "success" || alertType === "error") {
      setJiraKey("");
    }
    setShowAlert(false);
    setAlertType("warning");
  };

  const isEnableToProceed = () =>
    Object.keys(validationForms).every((fieldName) => !validationForms[fieldName]);

  return {
    jiraKey,
    showAlert,
    formValues,
    options,
    alertType,
    errors: errorsState,
    isLoading,
    isCompletedForm: isEnableToProceed(),
    isDisabledConfirmAction: getIsDisabledSave(),
    closeShowAlert,
    onCreateTicket: handleOnCreateTicket,
    handleOnChange: handleOnChangeValue,
    handleOnBlur,
    handleCancel: handleOnCancel,
    openShowAlert: handleOnShowAlert,
  };
};

export default useSupport;
