import React, { useState } from "react";
import { useStore } from "store/storeUtils";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import Client from "gqlclient/Client";
import { useTranslation } from "@tecma/i18n";
import { Button, Input, Spinner } from "@tecma/ds";
import { useMutation } from "@apollo/client";

import { LoginWrapper } from "components/LoginWrapper";
import { useForm } from "react-hook-form";

const EMAIL_REGEX = RegExp(
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i,
);

const ForgotPasswordContent = observer(({ onSuccess }) => {
  const store = useStore();
  const history = useHistory();

  const { t } = useTranslation();

  const goToLogin = () => {
    history.push("/login");
  };

  const tokenExpiredOrInvalid =
    history.location.state?.error === "expiredToken" ||
    history.location.state?.error === "invalidToken";

  // const initialValues = { email: store.vendorEmail };
  const [formError, setFormError] = useState(
    tokenExpiredOrInvalid ? "businessplatform.expiredToken" : null,
  );
  const [loading, setLoading] = useState(false);
  const [askChangePassword] = useMutation(Client.ASK_CHANGE_PASSWORD);

  const {
    register,
    handleSubmit,
    formState: { errors },
    // watch,
    // setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: store.vendorEmail,
    },
  });

  // const email = watch("email");

  const onSubmit = (formData) => {
    setLoading(true);
    askChangePassword(
      Client.ASK_CHANGE_PASSWORD_DEFAULT_OPTIONS(
        store.project_id,
        formData.email,
        "changePasswordBP",
      ),
    )
      .then((res) => {
        if (res.data.askChangePasswordBP.error) {
          switch (res.data.askChangePasswordBP.error) {
            case "noClientEmail": {
              setFormError("businessplatform.noUser");
              break;
            }
            case "userDisabled": {
              history.push("/login", { from: "forgotPassword", error: "userDisabled" });
              break;
            }
            default: {
              setFormError("businessplatform.passwordError");
              break;
            }
          }
        } else {
          onSuccess();
        }
      })
      .catch(() => {
        setFormError("businessplatform.passwordError");
      })
      .finally(() => setLoading(false));
  };

  // useEffect(() => {
  //   if (
  //     history.location.state?.error === "expiredToken" ||
  //     history.location.state?.error === "invalidToken"
  //   ) {
  //     setFormError("businessplatform.expiredToken");
  //   }
  // }, []);

  // const keyboardProps = {
  //   initialValues,
  //   formValues: {
  //     email,
  //   },
  //   setFormValues: (newValues) => {
  //     setValue("email", newValues.email);
  //   },
  //   keyboardFocus,
  //   setKeyBoardFocus,
  //   onSubmit,
  //   keyboardFields: {
  //     email: {},
  //   },
  // };

  return (
    <LoginWrapper className='forgot-password'>
      <LoginWrapper.Header>
        {tokenExpiredOrInvalid ? (
          <span className='login-wrapper-header-title'>{t("businessplatform.requestNewLink")}</span>
        ) : (
          <span className='login-wrapper-header-title'>{t("businessplatform.forgotPassword")}</span>
        )}
      </LoginWrapper.Header>
      <LoginWrapper.Content
        onSubmit={handleSubmit(onSubmit)}
        showAlert={formError}
        alertProps={{ title: formError }}
      >
        <div className='login-wrapper-content-wrapper'>
          <Input
            className='login-wrapper-content-input'
            label={t("businessplatform.forgotPassword.email")}
            name='text-input-2'
            helpTextPosition='down'
            status={errors.email && "error"}
            helpText={
              errors.email &&
              (errors.email?.type === "required"
                ? t("businessplatform.resetPassword.field-required")
                : errors.email?.type === "pattern"
                ? t("businessplatform.validMail")
                : "error")
            }
            type='email'
            placeholder={t("businessplatform.forgotPassword.placeholder.email")}
            {...register("email", {
              required: true,
              pattern: EMAIL_REGEX,
            })}
          />
        </div>
        <Button type='submit' fluid>
          {loading && <Spinner />}
          {t("businessplatform.forgotPassword.send")}
        </Button>
        <Button onClick={goToLogin} fluid color='transparent'>
          {t("businessplatform.forgotPassword.backToLogin")}
        </Button>
      </LoginWrapper.Content>
    </LoginWrapper>
  );
});

export default ForgotPasswordContent;
