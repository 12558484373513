import Home from "pages/Home/Home";
import ManagerActivation from "pages/ManagerActivation";

//esempio di import con "lazy"
//const ToBeDefined = React.lazy(() => import("pages/TBD"));

export const HomeRoute = {
  id: "home",
  order: 0,
  exact: true,
  to: "/",
  title: "Dashboard",
  navbarTitle: "Dashboard",
  icon: "",
  component: Home,
};

export const RegistrationRoute = {
  id: "registration",
  order: 1,
  exact: true,
  to: "/registration",
  title: "Registration",
  navbarTitle: "Registration",
  icon: "",
  component: ManagerActivation,
};

export const ChangePasswordRoute = (hostKey, token, isPCTuser) => {
  return isPCTuser
    ? "/forgotPassword?isPCTuser=true"
    : "/changePassword?hostname=" + hostKey + "&token=" + token;
};

const RouteConsts = [HomeRoute, RegistrationRoute, ChangePasswordRoute];
export default RouteConsts;
