import React, { useState } from "react";
import { observer } from "mobx-react";

import ChangePasswordContent from "./ChangePasswordContent";
import ChangePasswordCompleted from "./ChangePasswordCompleted";

import "./change-password.scss";

/**
 * Pagina di change password
 * @component
 */
const ChangePassword = observer(() => {
  const [step, setStep] = useState(0);

  return (
    <>
      {step === 0 && <ChangePasswordContent onSuccess={() => setStep(1)} />}
      {step === 1 && <ChangePasswordCompleted />}
    </>
  );
});

export default ChangePassword;
