import React, { useState } from "react";
import { observer } from "mobx-react";

import ActivateAccountContent from "./ActivateAccountContent";
import ActivateAccountCompleted from "./ActivateAccountCompleted";

import "./activate-account.scss";

const ActivateAccount = observer(() => {
  const [step, setStep] = useState(0);

  return (
    <>
      {step === 0 && <ActivateAccountContent onSuccess={() => setStep(1)} />}
      {step === 1 && <ActivateAccountCompleted />}
    </>
  );
});

export default ActivateAccount;
