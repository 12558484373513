import React from "react";
import SnackbarWrapper from "components/generic/SnackbarWrapper";
import { useStore } from "store/storeUtils";
import { observer } from "mobx-react";

const SnackbarFeedback = observer(() => {
  const store = useStore();

  return (
    <SnackbarWrapper
      open={store.snackbarOpen}
      onClose={() => store.setSnackbar(false)}
    >
      <div className="snackbar-body">{store.snackbarMessage}</div>
    </SnackbarWrapper>
  );
});

export default SnackbarFeedback;
