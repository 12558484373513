import psl from "psl";

/**
 * Get url parameter by name
 * @param {String} name value to insert
 */
const getParamByName = (name) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(name);
};

/**
 * Check if the backTo parameter is valid in order to prevent open redirection problems
 *
 * In production only allows backTo with the same domain as businessplatform domain.
 * In all other environments allows backTo with tecmasolutions.com or localhost domain
 *
 * @param {String} backToParam backTo decoded param to be validated
 * @returns {Boolean} backTo param validation result
 */
function isValidBackToParam(backToParam) {
  try {
    const businessplatformHostname = window.location.hostname;
    const businessplatformDomain = psl.parse(businessplatformHostname).domain;
    const backToHostname = new URL(backToParam).hostname;
    const backToDomain = psl.parse(backToHostname).domain;
    const isProduction =
      businessplatformHostname !== "localhost" && businessplatformDomain !== "tecmasolutions.com";

    if (isProduction) {
      return businessplatformDomain === backToDomain;
    } else {
      return backToHostname === "localhost" || backToDomain === "tecmasolutions.com";
    }
  } catch (e) {
    return false;
  }
}

const addTrailingSlash = (url) => {
  const newUrl = url.endsWith("/") ? url : `${url}/`;
  return newUrl;
};

export { getParamByName, isValidBackToParam, addTrailingSlash };
