import i18n from "@tecma/i18n";
import { ISSUE_TYPES, BROWSER_ICONS_NAMES } from "constants/SupportFormConstants";

export const generateIssueTypeOptions = () =>
  ISSUE_TYPES.map(({ label, type }) => ({
    value: label,
    label: i18n.t(`businessplatform.support.requestType.values.${label}`, {
      defaultValue: label,
    }),
    type: type,
  }));

export const generateJIRAFieldOptions = (values, valuesType) =>
  values.map((value) => ({
    value,
    label: value === "Other" ? i18n.t(`businessplatform.support.${valuesType}.Other`) : value,
    ...(valuesType === "browser" ? { icon: BROWSER_ICONS_NAMES[value] } : {}),
  }));
