import React from "react";
import SnackbarWrapper from "components/generic/SnackbarWrapper";
import { useStore } from "store/storeUtils";
import { observer } from "mobx-react";

const SnackbarError = observer(() => {
  const store = useStore();

  return (
    <SnackbarWrapper
      open={store.snackbarError}
      onClose={() => store.setSnackbarError(false)}
    >
      <div className="snackbar-body-error">{store.snackbarMessage}</div>
    </SnackbarWrapper>
  );
});

export default SnackbarError;
