/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "store/storeUtils";
import { useMutation } from "@apollo/client";
import Client from "gqlclient/Client";
import Countdown from "react-countdown";
import moment from "moment";
import psl from "psl";
import Cookies from "js-cookie";
import { HomeRoute } from "routes/Routes";
import { getParamByName, isValidBackToParam } from "utils/urlParamUtils";
import { useHistory } from "react-router-dom";
import { useTranslation } from "@tecma/i18n";

import { logger } from "@tecma/logs-fe";
import { Button, Input, Modal } from "@tecma/ds";

import convertLanguage from "utils/convertLanguage";

const FollowupFormOtp = observer((props) => {
  const {
    globalLoaderVal,
    setGlobalLoaderVal,
    otpValidationKeyBE,
    handleTwoFA,
    handleLogin,
    email,
    password,
    openExpiredPassModal,
    layoutName,
    layout,
    display,
    setKeyboard,
    keyboardVisibleOtp,
    setKeyboardVisibleOtp,
    handleClear,
    handleShift,
    handleLock,
    matchesPhone,
  } = props;

  const store = useStore();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [otpToken, setOtpToken] = React.useState("");
  const [optMissing, setOtpMissing] = React.useState(false);
  const [otpValidationKey, setOtpValidationKey] = React.useState(otpValidationKeyBE);

  const [generateOTP] = useMutation(Client.GENERATE_OTP, {
    onError: (err) => {
      logger.error(err);
    },
  });
  const [login, loginData] = useMutation(Client.LOGIN_OTP, {
    onError: (err) => {
      logger.error(err);
    },
  });

  const [countdownFinished, setCountdownFinished] = useState(false);
  const [invalidOTP, setInvalidOTP] = useState(false);

  const doneCalling = loginData && loginData.called && !loginData.loading;

  let domain = "localhost";
  if (window.location.hostname !== "localhost") {
    const pslUrl = psl.parse(window.location.hostname);
    domain = pslUrl.domain;
  }
  const cookieOptions = {
    expires: 1, // durata di 1 giorno, se non viene messa la durata diventa un session cookie che viene eliminato dopo aver chiuso il browser
    domain: domain,
  };
  const loggedInStuff = (data) => {
    if (!store.errorModalClient) {
      store.setLoggedUser(data.user);
      if (i18n.language.split("-")[0] !== data?.user?.language) {
        i18n.changeLanguage(convertLanguage(data?.user?.language));
      }

      // Salvo jwt token e vendorId nel cookie di dominio
      Cookies.set("jwt", data.token.accessToken, cookieOptions);
      Cookies.set("refreshToken", data.token.refreshToken, cookieOptions);
      Cookies.set("expiresIn", data.token.expiresIn, cookieOptions);
      Cookies.set("vendorId", data.user.id, cookieOptions);
      Cookies.set("userLanguage", data.user.language, cookieOptions);

      // Se siamo tornati su businessplatform solo per il login
      const backTo = getParamByName("backTo");
      if (backTo && isValidBackToParam(backTo)) {
        window.location = decodeURIComponent(backTo);
      }
      history.push(HomeRoute.to);
    }
  };

  React.useEffect(() => {
    if (doneCalling && loginData.error) {
      console.error("Error while validating OTP", loginData.error);
      setInvalidOTP(true);
      return;
    }
    if (doneCalling && loginData.data?.loginByProjectOTP?.user?.role !== "client") {
      loggedInStuff(loginData.data.loginByProjectOTP);
    } else if (doneCalling && loginData.data?.loginByProjectOTP?.user?.role === "client") {
      store.setErrorModal(true);
    } else if (loginData.error && loginData.error.message === "Expired") {
      store.setErrorModal(true);
    } else if (loginData.data?.loginByProjectOTP?.token?.tokenType === "userDisabled") {
      store.setSnackbarError(true, t("businessplatform.loggedUserDisabled"));
    } else if (
      doneCalling &&
      loginData.data?.loginByProjectOTP?.token?.tokenType === "ExpiredPassword"
    ) {
      openExpiredPassModal(
        loginData.data.loginByProjectOTP.token.expiredToken,
        loginData.data.loginByProjectOTP.token.hostKey,
        true,
      );
    } else if (loginData.error && loginData.error.message === "Credenziali Errate") {
      store.setSnackbarError(true, t("businessplatform.invalidPassword"));
    } else if (loginData.error && loginData.error.message === "OTP Errato") {
      store.setSnackbarError(true, t("businessplatform.invalidOtp"));
    } else if (loginData.error) {
      const finalMessage = loginData.error.message
        ? loginData.error.message.includes(":")
          ? loginData.error.message.split(":")[1]
          : loginData.error.message
        : loginData.error.message;
      store.setSnackbarError(true, finalMessage);
    }
    // eslint-disable-next-line
  }, [doneCalling]);

  React.useEffect(() => {
    if (loginData) {
      const newVal = loginData.called && loginData.loading;
      if (globalLoaderVal !== newVal) setGlobalLoaderVal(newVal);
    }
    // eslint-disable-next-line
  }, [globalLoaderVal, loginData, loginData.called, loginData.loading]);

  // eslint-disable-next-line no-unused-vars
  const generateNewOTP = () => {
    generateOTP(Client.GENERATE_OTP_DEFAULT_OPTIONS(store.project_id, email, false))
      .then((res) => {
        const otp = res.data.generateOTP;
        if (otp.error) {
          setOtpValidationKey(null);
          logger.error(t("businessplatform.errorSendOtp"), otp.error);
          store.setSnackbarError(true, otp.error);
        } else {
          setOtpValidationKey(res.data.generateOTP);
        }
      })
      .catch((e) => {
        setOtpValidationKey(null);
        logger.error(e);
        store.setSnackbarError(true, t("businessplatform.errorSendOtp"));
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // generateNewOTP();
  };

  const onResend = (e) => {
    e.preventDefault();
    handleLogin();
  };

  const tryLogin = () => {
    if (!otpToken) {
      setOtpMissing(true);
    }
    if (otpValidationKey && otpToken) {
      // eslint-disable-next-line no-unused-vars
      const { __typename, ...fileotp } = otpValidationKey;
      login({
        variables: {
          input: {
            email: email,
            password: password, //password,
            project_id: store.project_id,
            otp: otpToken,
            otpValidationKey: fileotp,
          },
        },
      });
    }
  };

  const date = otpValidationKey && otpValidationKey.expirationDate;
  const renderer = ({ minutes, seconds, completed }) => {
    /*if (completed && otpValidationKey && !otpValidationKey.loading) {
      setOtpValidationKey(null);
    }*/
    const minutesString = minutes < 10 ? "0" + minutes : "" + minutes;
    const secondsString = seconds < 10 ? "0" + seconds : "" + seconds;
    return (
      <>
        {!completed && (
          <p>
            {t("businessplatform.OtpValidFor", { minutes: minutesString, seconds: secondsString })}
          </p>
        )}
      </>
    );
  };

  const addText = (text) => {
    setOtpToken(otpToken + text);
  };

  const onKeyPress = (button) => {
    switch (button) {
      case "{enter}":
        tryLogin();
        break;
      case "{clear}":
        handleClear();
        setOtpToken("");
        break;
      case "{shift}":
        handleShift();
        break;
      case "{lock}":
        handleLock();
        break;
      case "{bksp}": {
        const text = otpToken.slice(0, -1);
        setOtpToken(text);
        break;
      }
      case "{space}":
        addText(" ");
        break;
      default:
        addText(button);
    }
  };

  const otpError = countdownFinished
    ? t("businessplatform.otpExpired")
    : invalidOTP
    ? t("businessplatform.otpInvalid")
    : undefined;

  return (
    <Modal isOpen>
      <Modal.Header
        title={
          otpValidationKey
            ? otpValidationKey.expirationDate
              ? t("businessplatform.verifyOtp")
              : t("businessplatform.wait")
            : t("businessplatform.requestOTP")
        }
        onClose={() => {
          handleTwoFA();
        }}
        isBackgroundDark
        closeIcon
      />
      <div className='divider' />
      <Modal.Content style={{ minHeight: "16rem", display: "flex", flexDirection: "column" }}>
        <h3>{t("businessplatform.insertOtp")}</h3>
        <Input
          type='text'
          placeholder={t("businessplatform.OtpCode")}
          style={{ marginTop: "1rem" }}
          status={otpError && "error"}
          helpText={otpError}
          value={otpToken}
          onChange={(e) => {
            setOtpMissing(false);
            setOtpToken(e.target.value);
          }}
        />
        <Countdown
          date={(date ? moment(date) : moment()).valueOf()}
          renderer={renderer}
          onComplete={() => {
            setCountdownFinished(true);
          }}
        />
        <div style={{ marginTop: "auto" }}>
          <div>
            <span>{t("businessplatform.didntReceiveOtp")}</span>
          </div>
          <a onClick={onResend} className='modal-link' href='#'>
            {t("businessplatform.sendAgainOtp")}
          </a>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <Button
          onClick={() => {
            handleTwoFA();
          }}
          outlined
        >
          {t("businessplatform.cancel.lowercase")}
        </Button>
        <Button
          onClick={() => {
            tryLogin();
          }}
          disabled={countdownFinished || optMissing}
        >
          {t("businessplatform.confirm.lowercase")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default FollowupFormOtp;
