import React from "react";
import PropTypes from "prop-types";
import Portal from "@material-ui/core/Portal";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialogcontent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CrmButton from "components/generic/CrmButton";
import "styles/modal.scss";

/**
 * A default, simple error modal
 * based on material-ui Dialog
 * @component
 * @see {@link https://material-ui.com/api/dialog/ Dialog API}
 */
const CrmSimpleModal = (props) => {
  const {
    disablePrimary,
    title,
    children,
    onClose,
    dialogClasses,
    extraContentClasses,
    actionToDo,
    buttonLabel,
    cancelLabel,
    cancelAction,
    open,
    maxWidth,
    styleActionButton,
    styleCancelButton,
    UnderButtonText,
    subTitle,
  } = props;

  const action = () => {
    actionToDo();
    onClose();
  };

  const cancel = () => {
    cancelAction();
    onClose();
  };

  return (
    <Portal>
      <Dialog
        maxWidth={maxWidth}
        open={open}
        classes={dialogClasses}
        onClose={onClose}
      >
        <DialogTitle
          classes={{
            root: "modal-title light",
          }}
          disableTypography
        >
          {title}
        </DialogTitle>
        <div>{subTitle}</div>
        <Dialogcontent
          classes={{
            root: `modal-content ${extraContentClasses}`,
          }}
        >
          {children}
        </Dialogcontent>
        <DialogActions classes={{ root: "modal-actions" }}>
          {cancelAction && (
            <CrmButton
              onClick={cancel}
              color="primary"
              style={styleCancelButton}
            >
              {cancelLabel}
            </CrmButton>
          )}
          <CrmButton
            onClick={action}
            disabled={disablePrimary}
            color="primary"
            style={styleActionButton}
          >
            {buttonLabel}
          </CrmButton>
        </DialogActions>
        <div>{UnderButtonText}</div>
      </Dialog>
    </Portal>
  );
};

CrmSimpleModal.defaultProps = {
  extraContentClasses: "",
  maxWidth: "sm",
  dialogClasses: {},
  title: "",
  disablePrimary: false,
  open: false,
  buttonLabel: "Ok",
  cancelLabel: "Indietro",
  onClose: () => {},
  actionToDo: () => {},
  styleActionButton: {},
  styleCancelButton: {},
  subTitle: "",
  UnderButtonText: "",
};

/** prop types */
CrmSimpleModal.propTypes = {
  /** extraContentClasses: addtional classes for the dialog content */
  extraContentClasses: PropTypes.string,
  /** maxWidth: sets max-width of container (material-ui prop) */
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false]),
  /** dialogClasses  classes for the dialog container */
  dialogClasses: PropTypes.object,
  /** disablePrimary: condition to disable primary button */
  disablePrimary: PropTypes.bool,
  /** title: string */
  title: PropTypes.string,
  /** buttonLabel: label for the primary button */
  buttonLabel: PropTypes.string,
  /** cancelLabel: label for the secondary button */
  cancelLabel: PropTypes.string,
  /** open: required boolean */
  open: PropTypes.bool.isRequired,
  /** onClose: required action */
  onClose: PropTypes.func.isRequired,
  /** actionToDo: additional action for the primary button */
  actionToDo: PropTypes.func,
  /** cancelAction: additional action for the secondary button. If set, the button is rendered */
  cancelAction: PropTypes.func,
  /** children: modal content */
  children: PropTypes.node,
  subTitle: PropTypes.node,
  styleActionButton: PropTypes.object,
  styleCancelButton: PropTypes.object,
  UnderButtonText: PropTypes.node,
};

export default CrmSimpleModal;
