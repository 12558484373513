import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import Cookies from "js-cookie";

const API = () => {
  const getToken = () => {
    const token = Cookies.get("jwt");
    return token ? `Bearer ${token}` : "";
  };

  const errorLink = onError(({ networkError }) => {
    if (networkError && networkError.statusCode) console.error(`ERRORE ${networkError.statusCode}`);
  });
  const httpLink = createHttpLink({
    credentials: "include",
    errorLink,
    uri: process.env.REACT_APP_APOLLO_URI,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: getToken(),
      },
    };
  });

  return new ApolloClient({
    credentials: "include",
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
};

export default API;
