import moment from "moment";

const OPEN_SUPPORT_MODAL_EXPIRED_SECONDS = 30;

export const couldOpenSupportModal = (timestampParam) => {
  const timestamp = new URLSearchParams(timestampParam).get("openSupport");
  return timestamp &&
    moment().diff(moment(+timestamp), "seconds") < OPEN_SUPPORT_MODAL_EXPIRED_SECONDS
    ? true
    : false;
};
