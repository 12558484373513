import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "@tecma/i18n";

import "./serviceStatusTag.scss";
import { Icon } from "@tecma/ds";

const ServiceStatusTag = ({ status }) => {
  const { t } = useTranslation();
  const getStatusClass = () => {
    if (status) {
      switch (status.toLowerCase()) {
        case "available":
          return "available";
        case "maintenance":
          return "maintenance";
        case "unavailable":
          return "unavailable";
        case "comingsoon":
          return "comingSoon";
        default:
          return "";
      }
    }
  };

  const getStatusText = () => {
    if (status) {
      switch (status.toLowerCase()) {
        case "available":
          return t("businessplatform.statuses.available");
        case "maintenance":
          return t("businessplatform.statuses.maintenance");
        case "unavailable":
          return t("businessplatform.statuses.unavailable");
        case "comingsoon":
          return t("businessplatform.cominSoon");
        default:
          return "";
      }
    }
  };

  return status ? (
    <p data-testid={`${status}Tool`} className={`service-status-tag ${getStatusClass()} `}>
      <Icon iconName='dot' filled size='small' /> {getStatusText()}
    </p>
  ) : (
    <></>
  );
};

ServiceStatusTag.propTypes = {
  status: PropTypes.string,
};

export default ServiceStatusTag;
