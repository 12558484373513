import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useTranslation } from "@tecma/i18n";

const Header = observer(({ title, subtitle, children, className, ...rest }) => {
  const classList = classNames("login-wrapper-header", className);
  const { t } = useTranslation();
  return (
    <div className={classList} {...rest}>
      {title && <span className='login-wrapper-header-title'>{t(title)}</span>}
      {subtitle && <span className='login-wrapper-header-subtitle'>{t(subtitle)}</span>}
      {children}
    </div>
  );
});

export default Header;
