import { observable, action, decorate, computed } from "mobx";
import { isEqual, omit, cloneDeep } from "lodash";
import filterToolsAndProducts from "utils/FilterToolsAndProducts";
import convertLanguage from "utils/convertLanguage";

/** Class representing global state of the app.
 * Store is decorated with mobx.
 * It uses the following mobx and mobx-persist decorators:
 * - observable: variable is set as observable (observers will be notified if the variable is updated)
 * - persist: variable will be persisted in a storage
 * - action(.bound): the function is enabled to update the store. Its "this" is bound to the store.
 * - computed: after an observable update, this function automatically updates a complex variable
 * @see {@link https://mobx.js.org/|mobx}
 * @see {@link https://www.npmjs.com/package/mobx-persist|mobx-persist}
 */
class Store {
  /**
   * initializes store.
   * @constructor
   */
  constructor() {
    this.loginProjectName = "";
    this.projectName = null;
    this.hostKey = null;
    this.projectId = null;
    this.logo = [];
    this.assetLogin = [];
    this.assetsUrl = null;
    this.enabledTools = [];
    this.snackbarOpen = false;
    this.snackbarMessage = undefined;
    this.snackbarError = false;
    this.vendorEmail = "";
    this.avatarMenu = false;
    this.langMenu = false;
    this.errorModal = false;
    this.loggedUser = null;
    this.project_id = undefined;
    this.vendorLogo = [];
    this.pageTitles = undefined;
    this.accountManagerEnabled = false;
    this.modalOtp = false;
    this.products = [];
    this.systemLanguage = null;
    this.forcingLogout = false;
    this.checkingJwt = null;
    this.keyboardVisible = false;
    this.languages = undefined;
    this.baseUrl = undefined;
    this.supportMessageSubject = "";
    this.supportMessageDescription = "";
    this.area = "";
    this.defaultLang = "";
    this.isTablet = undefined;
  }

  /* ACTIONS */
  setBaseUrl(val) {
    this.baseUrl = val;
  }

  setSupportMessageSubject(subject) {
    this.supportMessageSubject = subject;
  }

  setSupportMessageDescription(description) {
    this.supportMessageDescription = description;
  }

  setAssetsByObject(obj) {
    if (obj) {
      this.loginProjectName = obj.displayName;
      this.projectName = obj.name;
      this.hostKey = obj.hostKey;
      this.projectId = obj.id;
      this.project_id = obj.id;
      this.assetsUrl = obj.assetsUrl;
      this.assetLogin = obj.assetLogin;
      this.logo = obj.logo;
      this.vendorLogo = obj.vendorLogo;
      this.pageTitles = obj.pageTitles;
      this.enabledTools = obj.enabledTools;
      this.accountManagerEnabled = obj.accountManagerEnabled;
      this.baseUrl = obj.baseurl;
      this.languages = obj.businessPlatformConfig
        ? obj.businessPlatformConfig.languages
        : undefined;
      this.area = obj.area;
      this.defaultLang = obj.defaultLang;
    }
  }

  setAccountManagerEnabled(value) {
    this.accountManagerEnabled = value;
  }

  setProducts(value, toolStatus) {
    if (toolStatus) {
      const products = [];
      value.forEach((tool) => {
        const status = toolStatus.filter((ts) => filterToolsAndProducts(ts, tool))[0];
        if (status) {
          const myTool = cloneDeep(tool);
          myTool.status = status.status;
          products.push(myTool);
        }
      });

      this.products = [...products];
    } else {
      this.products = [...value];
    }
  }

  /**
   * sets the logged user
   * @param {Object} userData the logged user object
   */
  setLoggedUser(userData, withCheckOTP = false) {
    if (
      !this.loggedUser ||
      !isEqual(omit(this.loggedUser, ["language", "TwoFA"]), omit(userData, ["language", "TwoFA"]))
    ) {
      this.loggedUser = userData;
    }
    if (userData.firstLogin && !userData.TwoFA && withCheckOTP) {
      this.setModalOtp(true);
    }
  }

  setLoggedUserFirstLogin(value) {
    this.loggedUser.firstLogin = value;
  }
  setLoggedUserTwoFA(value) {
    this.loggedUser.TwoFA = value;
  }

  setModalOtp(value) {
    if (!value) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
    this.modalOtp = value;
  }

  /**
   * sets the logged user language
   * @param {Object} userData the logged user object
   */
  setLoggedUserLang(language, locale) {
    if (language) {
      this.loggedUser.language = language;
    }
    if (locale) {
      this.loggedUser.locale = locale;
    }
  }

  /**
   * set the language of the system
   * @param {string} val
   */
  setSystemLanguage(val) {
    this.systemLanguage = val;
  }

  setForcingLogout(val) {
    this.forcingLogout = val;
  }

  setCheckingJwt(val) {
    this.checkingJwt = val;
  }

  /**
   * sets the loging in vendor email
   * @param {string} val the email
   */
  updateVendorEmail(val) {
    if (this.vendorEmail !== val) {
      this.vendorEmail = val;
    }
  }

  /**
   * open/close error modal
   * @param {boolean} val
   */
  setErrorModal(val) {
    this.errorModal = val;
  }
  /**
   * sets avatar's menu state (open/closed).
   * @param {boolean} val state of the menu
   */
  setAvatarMenu(val) {
    this.avatarMenu = val;
  }
  /**
   * sets language's menu state (open/closed).
   * @param {boolean} val state of the menu
   */
  setLangMenu(val) {
    this.langMenu = val;
  }

  setSnackbarError(err, mes) {
    if (err !== this.snackbarError) {
      this.snackbarMessage = mes;
      this.snackbarError = err;
    }
  }

  /**
   * sets snackbar open/closed
   * @param {boolean} val
   * @param {string} mes message to display
   */
  setSnackbar(val, mes) {
    if (val !== this.snackbarOpen) {
      this.snackbarMessage = mes;
      this.snackbarOpen = val;
    }
  }

  /**
   * open/close keyboard
   * @param {boolean} val
   */
  setKeyBoardVisible(value) {
    this.keyboardVisible = value;
  }

  /**
   * save device dimension
   */
  setDeviceDimension(value) {
    if (value <= 1023) {
      this.isTablet = true;
    } else {
      this.isTablet = false;
    }
  }

  /* COMPUTED */

  /**
   * computes the logged user full name
   */
  get loggedUserFullname() {
    if (this.loggedUser && this.loggedUser.firstName && this.loggedUser.lastName) {
      return `${this.loggedUser.firstName} ${this.loggedUser.lastName}`;
    } else if (this.loggedUser && this.loggedUser.id) {
      return this.loggedUser.id;
    } else {
      return "-";
    }
  }

  get configLanguages() {
    if (Array.isArray(this.languages)) {
      return this.languages.map((lang) => {
        return convertLanguage(lang);
      });
    }
    return ["it-IT", "en-GB"];
  }
}

decorate(Store, {
  snackbarMessage: observable,
  snackbarError: observable,
  snackbarOpen: observable,
  modalOtp: observable,
  loginProjectName: observable,
  projectName: observable,
  hostKey: observable,
  projectId: observable,
  project_id: observable,
  assetsUrl: observable,
  area: observable,
  defaultLang: observable,
  accountManagerEnabled: observable,
  assetLogin: observable,
  logo: observable,
  vendorLogo: observable,
  pageTitles: observable,
  enabledTools: observable,
  products: observable,
  keyboardVisible: observable,
  systemLanguage: observable,
  loggedUser: observable,
  forcingLogout: observable,
  checkingJwt: observable,
  languages: observable,
  baseUrl: observable,
  supportMessageSubject: observable,
  supportMessageDescription: observable,
  isTablet: observable,
  vendorEmail: observable,
  setAssetsByObject: action.bound,
  updateVendorEmail: action.bound,
  setAvatarMenu: action.bound,
  setLoggedUser: action.bound,
  setLoggedUserFirstLogin: action.bound,
  setLoggedUserTwoFA: action.bound,
  setModalOtp: action.bound,
  setLoggedUserLang: action.bound,
  setErrorModal: action.bound,
  setSnackbarError: action.bound,
  setSystemLanguage: action.bound,
  loggedUserFullname: computed,
  configLanguages: computed,
  setForcingLogout: action.bound,
  setCheckingJwt: action.bound,
  setKeyBoardVisible: action.bound,
  setBaseUrl: action.bound,
  setSupportMessageSubject: action.bound,
  setSupportMessageDescription: action.bound,
});

export default Store;
