import React from "react";

import "./footer.scss";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className='footer'>
      <span className='footer-text'>&copy; {year} TECMA Business Technology Platform</span>
    </footer>
  );
};

export default Footer;
