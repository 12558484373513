import { useMutation } from "@apollo/client";
import Client from "gqlclient/Client";
import { useHistory } from "react-router-dom";
import { useStore } from "store/storeUtils";
import { logout } from "utils/auth";
import { addTrailingSlash } from "utils/urlParamUtils";

const useLogout = () => {
  const [logoutMutation] = useMutation(Client.LOGOUT);
  const store = useStore();
  const history = useHistory();

  const handleOnLogout = async () => {
    await logoutMutation();
    logout(history);
    if (!history) {
      const baseAppUrl = addTrailingSlash(store.baseUrl);
      window.location.replace(`${window.location.origin}${baseAppUrl}login`);
    }
  };

  return {
    logout: handleOnLogout,
  };
};

export default useLogout;
