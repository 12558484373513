export const VENDOR_MANAGER = "vendor_manager";
export const VENDOR = "vendor";
export const ENABLE_NEWSLETTER_POLICY = [VENDOR_MANAGER, VENDOR];

export const ACTIVATE_ACCOUNT_ERRORS = {
  expiredToken: {
    field: "validationCode",
    keyLabel: "exipred",
  },
  mismatchPassword: { field: "confirmPassword", keyLabel: "passwordNotMatch" },
  passwordEqualsEmail: { field: "newPassword", keyLabel: "passwordEqualsEmail" },
  passwordEqualsName: { field: "newPassword", keyLabel: "passwordEqualsName" },
  notSecurePassword: { field: "newPassword", keyLabel: "notSecurePassword" },
  passwordAlreadyUsed: { field: "newPassword", keyLabel: "passwordAlreadyUsed" },
};
