import isProd from "./isProd";

/**
 * gets actual host
 * @todo remove this utility
 */
const DetectUrl = () => {
  let hostname =
    window.location.hostname.indexOf("www.") >= 0
      ? window.location.hostname
      : "www." + window.location.hostname;
  const urlParams = new URLSearchParams(window.location.search);
  const hostnameParam = urlParams.get("hostname");

  if (!isProd()) {
    if (hostnameParam && hostnameParam.length > 0 && hostnameParam !== "") {
      localStorage.clear();
      delete localStorage["businessplatform_dev_hostname"];
      localStorage.setItem("businessplatform_dev_hostname", hostnameParam);
    }

    let businessplatform_dev_hostname = localStorage.getItem(
      "businessplatform_dev_hostname"
    );
    if (
      businessplatform_dev_hostname &&
      businessplatform_dev_hostname.length > 0 &&
      businessplatform_dev_hostname !== ""
    ) {
      return businessplatform_dev_hostname;
    }

    hostname = hostname || process.env.REACT_APP_HOST;
  }
  return hostname;
};

export default DetectUrl;
