import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "store/storeUtils";
import { useTranslation } from "@tecma/i18n";
import SupportAlert from "components/specific/SupportAlert";

import { Input, Modal, Select, Button, TextArea } from "@tecma/ds";
import headerBackground from "../../assets/images/ticket-template.jpeg";

//CONST
import { MAX_SUBJECT_LENGTH, MAX_DESCRIPTION_LENGTH } from "../../constants/SupportFormConstants";

import "styles/support.scss";
import useSupport from "hooks/useSupport";

/**
 * Pagina di support
 * @component
 */
const Support = observer(({ isOpen, closeModal }) => {
  const store = useStore();
  const { t } = useTranslation();

  const {
    formValues,
    options,
    jiraKey,
    showAlert,
    alertType,
    errors,
    isCompletedForm,
    openShowAlert,
    handleCancel,
    closeShowAlert,
    handleOnChange,
    handleOnBlur,
    onCreateTicket,
  } = useSupport(isOpen);

  const supportRef = useRef();

  const subjectHelpText = () => {
    if (formValues.subject.length > MAX_SUBJECT_LENGTH) {
      return (
        <>
          {t("businessplatform.support.charactersLimit", { length: MAX_SUBJECT_LENGTH })}
          {` (${formValues.subject.length}/${MAX_SUBJECT_LENGTH})`}
        </>
      );
    }
    if (formValues.subject.length === 0) {
      return <>{t("businessplatform.support.subjectRequired")}</>;
    }
    return (
      <>
        {t("businessplatform.support.charactersLimit", { length: MAX_SUBJECT_LENGTH })}
        {` (${formValues.subject.length || 0}/${MAX_SUBJECT_LENGTH})`}
      </>
    );
  };

  const descriptionHelpText = () => {
    if (formValues.description.length > MAX_DESCRIPTION_LENGTH) {
      return (
        <>
          {t("businessplatform.support.charactersLimit", { length: MAX_DESCRIPTION_LENGTH })}
          {` (${formValues.description.length}/${MAX_DESCRIPTION_LENGTH})`}
        </>
      );
    }
    if (formValues.description.length === 0) {
      return <>{t("businessplatform.support.descriptionRequired")}</>;
    }
    return (
      <>
        {t("businessplatform.support.charactersLimit", { length: MAX_DESCRIPTION_LENGTH })}
        {formValues.description.length / MAX_DESCRIPTION_LENGTH >= 0.8
          ? ` (${formValues.description?.length || 0}/${MAX_DESCRIPTION_LENGTH})`
          : ""}
      </>
    );
  };

  const descriptionWarning = () => {
    if (errors.description || formValues.description.length > MAX_DESCRIPTION_LENGTH) {
      return "error";
    }
    if (formValues.description.length / MAX_DESCRIPTION_LENGTH >= 0.8) {
      return "warning";
    }
    return "";
  };
  const subjectWarning = () => {
    if (errors.subject || formValues.subject.length > MAX_SUBJECT_LENGTH) {
      return "error";
    }
    if (formValues.subject.length / MAX_SUBJECT_LENGTH >= 0.8) {
      return "visible";
    }
    return "";
  };

  const handleOnCancelAlert = () => {
    handleCancel();
    closeModal();
  };

  const handleOnCloseAlert = () => {
    if (alertType === "success" || alertType === "error") {
      closeModal();
    }
    closeShowAlert();
  };

  return (
    <Modal isOpen={isOpen} size='medium'>
      <Modal.Header
        title={t("businessplatform.support.headerTitle")}
        subtitle={`Asset: ${store.loginProjectName}`}
        onClose={openShowAlert}
        backgroundImage={headerBackground}
        isBackgroundDark
        closeIcon
      />
      <Modal.Content className='support-modal-content'>
        <>
          <form className='support-modal-content__form' ref={supportRef}>
            <div className='section'>
              <div className='user-info'>
                <span>{t("businessplatform.support.user")}</span>
                <span className='user-info-name'>{`${store.loggedUser?.firstName} ${store.loggedUser?.lastName}`}</span>
                <span className='user-info-role'>
                  ({t(`businessplatform.support.role.${store.loggedUser?.role}`)})
                </span>
              </div>
              <div className='divider' />
            </div>
            <div className='section'>
              <div className='section-title'>
                <h2>{t("businessplatform.support.title")}</h2>
                <p className='mandatory-field'>*{t("businessplatform.support.mandatoryFields")}</p>
              </div>
              <p className='ticket-info'>{t("businessplatform.support.ticketInfo")}</p>
            </div>
            <div className='section'>
              <div className='section-title'>
                <p>{t("businessplatform.support.section.information")}</p>
              </div>
              <div className='section-content two-col'>
                <Select
                  name='issues'
                  options={options.issues}
                  onChange={(values) => handleOnChange("issues", values)}
                  required
                  value={formValues.issues}
                  error={errors.issues}
                  placeholder={t("businessplatform.support.requestType.placeholder")}
                  label={t("businessplatform.support.requestType.boxTitle")}
                  onBlur={handleOnBlur}
                  helpText={t("businessplatform.support.requestType.errorMessage")}
                />
                <Select
                  name='products'
                  options={options.products}
                  onChange={(values) => handleOnChange("products", values)}
                  required
                  value={formValues.products}
                  error={errors.products}
                  placeholder={t("businessplatform.support.product.placeholder")}
                  label={t("businessplatform.support.product.boxTitle")}
                  isMulti
                  onBlur={handleOnBlur}
                  helpText={t("businessplatform.support.product.errorMessage")}
                />
              </div>
            </div>
            <div className='section'>
              <div className='section-title'>
                <p>{t("businessplatform.support.section.description")}</p>
              </div>
              <div className='section-content'>
                <Input
                  name='subject'
                  value={formValues.subject}
                  required
                  label={t("businessplatform.support.subject")}
                  status={subjectWarning()}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleOnChange(name, value);
                  }}
                  onBlur={handleOnBlur}
                  placeholder={t("businessplatform.support.subjectPlaceholder")}
                  className={"form-value-description"}
                  helpText={errors.subject && subjectHelpText()}
                />
              </div>
              <div className='section-content two-col'>
                <Select
                  name='devices'
                  options={options.devices}
                  onChange={(values) => handleOnChange("devices", values)}
                  value={formValues.devices}
                  placeholder={t("businessplatform.support.device.placeholder")}
                  label={t("businessplatform.support.device.boxTitle")}
                  error={errors.devices}
                  isMulti
                  onBlur={handleOnBlur}
                  helpText={errors.devices && t("businessplatform.support.device.errorMessage")}
                />

                <Select
                  name='browsers'
                  options={options.browsers}
                  onChange={(values) => handleOnChange("browsers", values)}
                  value={formValues.browsers}
                  placeholder={t("businessplatform.support.browser.placeholder")}
                  required
                  label={t("businessplatform.support.browser.boxTitle")}
                  error={errors.browsers}
                  optionIcon={true}
                  isMulti
                  onBlur={handleOnBlur}
                  helpText={t("businessplatform.support.browser.errorMessage")}
                />
              </div>

              <div
                className={`section-content textarea-wrapper ${
                  errors.description ? "field-invalid" : ""
                }`}
              >
                <p className='support-form-description-title'>
                  {t("businessplatform.support.description")}*
                </p>
                <p className='support-form-description-label'>
                  {t("businessplatform.support.descriptionLabel")}
                </p>
                <TextArea
                  value={formValues.description}
                  name='description'
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleOnChange(name, value);
                  }}
                  onBlur={handleOnBlur}
                  placeholder={t("businessplatform.support.descriptionPlaceholder")}
                  className={`${descriptionWarning()}`}
                />
                <p className={`characters-length description ${descriptionWarning()}`}>
                  {descriptionHelpText()}
                </p>
              </div>
            </div>
          </form>
          <SupportAlert
            container={supportRef.current}
            isOpen={showAlert}
            onCancel={handleOnCancelAlert}
            onClose={handleOnCloseAlert}
            alertType={alertType}
            jiraKey={jiraKey}
          />
        </>
      </Modal.Content>
      <Modal.Footer className='support-modal-footer'>
        <Button iconName='trash' outlined rightIcon iconSize='small' onClick={openShowAlert}>
          {t("businessplatform.support.delete")}
        </Button>
        <Button
          onClick={onCreateTicket}
          disabled={!isCompletedForm}
          iconName='chevron-right'
          iconSize='small'
          rightIcon
        >
          {t("businessplatform.support.send")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default Support;
