import React, { useState } from "react";
import { observer } from "mobx-react";

import ForgotPasswordContent from "./ForgotPasswordContent";
import EmailSent from "./EmailSent";

import "./forgot-password.scss";

/**
 * Pagina di recupero password
 * @component
 */
const ForgotPassword = observer(() => {
  const [step, setStep] = useState(0);

  return (
    <>
      {step === 0 && <ForgotPasswordContent onSuccess={() => setStep(1)} />}
      {step === 1 && <EmailSent />}
    </>
  );
});

export default ForgotPassword;
