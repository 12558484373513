import fetchJson from "utils/fetchJson";
import Cookies from "js-cookie";

function createTicketMutation(input) {
  return {
    query: `mutation createTicket($input: TicketInput!){
        createTicket(
          input: $input
        ) {
          done
          errors
          jiraKey
        }
      }`,
    variables: {
      input,
    },
  };
}

function getJiraFieldOptionsQuery(input) {
  return {
    query: `query getJiraFieldOptions($input: FieldInput!){
      getJiraFieldOptions(
          input: $input
        ) {
          labels
        }
      }`,
    variables: {
      input,
    },
  };
}

function buildRequest(input) {
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Cookies.get("jwt"),
    },
    body: JSON.stringify(input),
  };
}

export async function createTicket(input) {
  const response = await fetchJson(
    process.env.REACT_APP_RENT_APOLLO_URI,
    buildRequest(createTicketMutation(input)),
  );

  if (response.errors) {
    return response.errors[0];
  } else {
    return response.data.createTicket;
  }
}

export async function getJiraFieldOptions(input) {
  const response = await fetchJson(
    process.env.REACT_APP_RENT_APOLLO_URI,
    buildRequest(getJiraFieldOptionsQuery(input)),
  );

  if (response.errors) {
    return response.errors[0];
  } else {
    return response.data?.getJiraFieldOptions?.labels ?? [];
  }
}
