import React, { useEffect } from "react";
import { useStore } from "store/storeUtils.js";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import Client from "gqlclient/Client";
import { logout } from "./auth";
import { logger } from "@tecma/logs-fe";
import { observer } from "mobx-react";

const JwtChecker = observer(() => {
  const store = useStore();
  const history = useHistory();

  const [logoutMutation] = useMutation(Client.LOGOUT);
  const [loadUserInfo, userInfo] = useLazyQuery(
    Client.GET_USER_INFO,
    Client.GET_USER_INFO_DEFAULT_OPTIONS(store.projectId),
  );

  const skipCondition = () => {
    return [
      "login",
      "changePassword",
      "forgotPassword",
      "resetPassword",
      "registration",
    ].some((route) => window.location.pathname.includes(route));
  };

  useEffect(() => {
    if (userInfo && !userInfo.loading && userInfo.called) {
      if (userInfo.data?.getUserByJWT) {
        store.setLoggedUser(userInfo.data.getUserByJWT);
      }
      if (userInfo.error || userInfo.data?.errors?.length) logout(history);
      store.setCheckingJwt(false);
    }
    // eslint-disable-next-line
  }, [userInfo]);

  useEffect(() => {
    if (store.forcingLogout) {
      logoutMutation()
        .then((res) => {
          if (res.data && res.data.logout === "Ok") {
            logout(history);
            store.setForcingLogout(false);
          }
        })
        .catch((err) => logger.error(err));
    }
    // eslint-disable-next-line
  }, [store.forcingLogout]);

  function handleVisibilityChange() {
    if (!document.hidden && !skipCondition()) {
      store.setCheckingJwt(true);
      loadUserInfo();
    }
  }

  useEffect(() => {
    window.addEventListener("visibilitychange", handleVisibilityChange);
    return () => window.removeEventListener("visibilitychange", handleVisibilityChange);
    // eslint-disable-next-line
  }, []);

  return <></>;
});

export default JwtChecker;
