import React from "react";
import PropTypes from "prop-types";
import Fade from "@material-ui/core/Fade";
import Snackbar from "@material-ui/core/Snackbar";

/**
 * Default snackbar
 * based on material-ui snackbar
 * Unknown props are passed down to the underlying MUI component
 * @component
 * @see {@link https://material-ui.com/api/snackbar/ Snackbar API}
 */
const SnackbarWrapper = (props) => {
  const { autoHideDuration, TransitionComponent, open, onClose, anchorOrigin, message, action, children, ...rest } = props;

  return (
    <Snackbar anchorOrigin={anchorOrigin} open={open} TransitionComponent={TransitionComponent} onClose={onClose} autoHideDuration={autoHideDuration} message={message} action={action} {...rest}>
      {children}
    </Snackbar>
  );
};

SnackbarWrapper.propTypes = {
  /** anchorOrigin: position of the snackbar */
  anchorOrigin: PropTypes.object,
  /** TransitionComponent: a material ui transition component */
  TransitionComponent: PropTypes.elementType,
  /** open: if true, element is open */
  open: PropTypes.bool,
  /** onClose */
  onClose: PropTypes.func,
  /** autoHideDuration: snackbars hides after x ms, set to null to disable */
  autoHideDuration: PropTypes.number,
  /** message: the message to display*/
  message: PropTypes.node,
  /** action: the action to display, renders after the message */
  action: PropTypes.node,
  /** children: modal content */
  children: PropTypes.element,
};

SnackbarWrapper.defaultProps = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  TransitionComponent: Fade,
  open: true,
  onClose: () => {},
  autoHideDuration: 5000,
};

export default SnackbarWrapper;
