import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import ReactKeyboard from "react-simple-keyboard";

const FULL_KEYBOARD_LAYOUT = {
  default: [
    "\\ 1 2 3 4 5 6 7 8 9 0 ' ì {bksp} {clear}",
    "{tab} q w e r t y u i o p è +",
    "{lock} a s d f g h j k l ò à ù {enter}",
    "{shift} < z x c v b n m , . - {shift}",
    "@ .com .net {space} .it .eu",
  ],
  shift: [
    '| ! " £ $ % & / ( ) = ? ^ {bksp} {clear}',
    "{tab} Q W E R T Y U I O P é *",
    "{lock} A S D F G H J K L ç ° § {enter}",
    "{shift} > Z X C V B N M ; : _ {shift}",
    "@ .com .net {space} .it .eu",
  ],
};
const FULL_KEYBOARD_DISPLAY = {
  "{clear}": "Clear",
  "{bksp}": "⌫",
  "{tab}": "⇥",
  "{lock}": "⇪",
  "{enter}": "Enter ↵",
  "{shift}": "⇧",
  "{space}": "  ",
};

const Keyboard = observer(
  ({
    onSubmit,
    onClear,
    setFormValues,
    formValues,
    initialValues,
    keyboardFields,
    keyboardFocus,
    setKeyBoardFocus,
  }) => {
    const [layoutName, setLayoutName] = useState("default");
    const [keyboard, setKeyboard] = useState(null);
    const [shift, setShift] = useState(false);
    const addText = (text) => {
      if (shift) {
        setLayoutName("default");
        setShift(false);
      }

      if (keyboardFocus && keyboardFields[keyboardFocus]?.onChange) {
        keyboardFields[keyboardFocus].onChange();
      } else {
        setFormValues({
          ...formValues,
          [keyboardFocus]: formValues[keyboardFocus] + text,
        });
      }
    };

    const handleShift = () => {
      setLayoutName(layoutName === "default" ? "shift" : "default");
      setShift(!shift);
    };
    const handleLock = () => {
      setLayoutName(layoutName === "default" ? "shift" : "default");
    };
    const handleClear = () => {
      if (keyboard) {
        keyboard.clearInput();
      }
    };

    const selectKeyboardFocus = () => {
      setKeyBoardFocus(Object.keys(keyboardFields)[0]);
    };

    const onKeyPress = (button) => {
      switch (button) {
        case "{tab}":
          if (keyboardFields) {
            const fields = Object.keys(keyboardFields);
            if (!keyboardFocus) {
              selectKeyboardFocus();
            }
            const currentIndex = fields.findIndex((field) => field === keyboardFocus);
            console.log("next focus: ", fields[currentIndex + 1]);
            setKeyBoardFocus(fields[currentIndex + 1]);
          }
          break;
        case "{enter}":
          onSubmit();
          break;
        case "{clear}":
          if (onClear) {
            onClear();
          } else {
            handleClear();
            setFormValues(initialValues);
          }
          break;
        case "{shift}":
          handleShift();
          break;
        case "{lock}":
          handleLock();
          break;
        case "{bksp}":
          if (keyboardFocus && keyboardFields[keyboardFocus]?.onDelete) {
            keyboardFields[keyboardFocus].onDelete();
          } else {
            const text = formValues[keyboardFocus].slice(0, -1);
            setFormValues({
              ...formValues,
              [keyboardFocus]: text,
            });
          }
          break;
        case "{space}":
          addText(" ");
          break;
        default:
          addText(button);
      }
    };

    useEffect(() => {
      if (!keyboardFocus && keyboardFields) {
        selectKeyboardFocus();
      }
    }, []);

    return (
      <ReactKeyboard
        layoutName={layoutName}
        keyboardRef={(r) => setKeyboard(r)}
        onKeyPress={onKeyPress}
        layout={FULL_KEYBOARD_LAYOUT}
        display={FULL_KEYBOARD_DISPLAY}
      />
    );
  },
);

export default Keyboard;
