import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import { initI18n, useTranslation, loadResources } from "@tecma/i18n";
import Client from "gqlclient/Client";
import { useStore } from "store/storeUtils";
import PrivateRoute from "routes/PrivateRoute";
import RouteConsts from "routes/Routes.jsx";
import { Home } from "pages/Home";
import Login from "pages/Login";
import ForgotPassword from "pages/ForgotPassword/ForgotPassword";
import ChangePassword from "pages/ChangePassword/ChangePassword";
import ActivateAccount from "pages/ActivateAccount/ActivateAccount";
import Support from "components/common/Support";
import convertLanguage from "utils/convertLanguage";
import inizializeAnalytics from "utils/analytics.js";
import SnackbarFeedback from "components/generic/SnackbarFeedback";
import SnackbarError from "components/generic/SnackbarError";
import CrmLoader from "components/generic/CrmLoader";
import DetectUrl from "utils/DetectUrl";
import JwtChecker from "utils/JwtChecker";
import translation from "./i18n/translation/en/translation.json";
import { setDeviceDimension } from "utils/setDevideDimension";
import useLogout from "hooks/useLogout";

export const i18n = initI18n(translation, {
  bucketBase: `${process.env.REACT_APP_BUCKET_BASEURL}`,
  productName: "businessplatform",
  apiBase: process.env.REACT_APP_API_URI || "",
  expirationTime: 1000 * 60, // 1 minuto,
  cache: "no-cache",
});

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CODE,
};

inizializeAnalytics(tagManagerArgs);

export const App = () => {
  const store = useStore();
  const [loading, setLoading] = React.useState(true);
  const [i18nLoading, seti18nLoading] = React.useState(true);
  const { i18n } = useTranslation();

  const { logout } = useLogout();
  const [loadUserInfo, userInfo] = useLazyQuery(
    Client.GET_USER_INFO,
    Client.GET_USER_INFO_DEFAULT_OPTIONS(store.projectId),
  );

  const skipCondition = () => {
    return ["login", "changePassword", "forgotPassword", "registration"].some((route) =>
      window.location.pathname.includes(route),
    );
  };

  const getUserInfo = (projectId) => {
    store.setCheckingJwt(true);
    if ((store.projectId || projectId) && !userInfo.called && !skipCondition()) {
      loadUserInfo();
    }
  };

  React.useEffect(() => {
    getUserInfo();

    // eslint-disable-next-line
  }, [store.projectId]);

  React.useEffect(() => {
    if (userInfo && !userInfo.loading && userInfo.called && userInfo.data?.getUserByJWT) {
      store.setCheckingJwt(false);
      store.setLoggedUser(userInfo.data.getUserByJWT);
      const userLanguage = userInfo.data?.getUserByJWT?.language;
      const userLocale = userInfo.data?.getUserByJWT?.locale;
      if (userLanguage && i18n.language.split("-")[0] !== userLanguage) {
        i18n.changeLanguage(convertLanguage(userLanguage, userLocale));
      }
    }
    if (userInfo.data?.errors?.length || userInfo.error) {
      logout();
    }
  }, [userInfo]);

  //obtain browser language
  const prjInfo = useQuery(
    Client.GET_PROJECT_INFO,
    Client.GET_PROJECT_INFO_DEFAULT_OPTIONS(DetectUrl()),
  );
  React.useEffect(() => {
    if (prjInfo?.data?.getProjectInfoByHost === null) {
      throw Error();
    }
    if (
      prjInfo &&
      !prjInfo.loading &&
      prjInfo.called &&
      prjInfo.data &&
      prjInfo.data.getProjectInfoByHost &&
      loading
    ) {
      const project = { ...prjInfo.data.getProjectInfoByHost };
      store.setAssetsByObject(project);
      store.setBaseUrl(prjInfo.data.getProjectInfoByHost.baseurl);
      getUserInfo(project.id);
      document.title = store.pageTitles.businessPlatform
        ? store.pageTitles.businessPlatform
        : `${store.loginProjectName} | Business Platform`;

      setLoading(false);
      loadResources({
        id: project.id,
        displayName: project.displayName,
        languages: store.languages,
      }).then(() => {
        seti18nLoading(false);
      });
    }
  }, [prjInfo]);

  React.useEffect(() => {
    setDeviceDimension(store);
    const handleResize = () => {
      setDeviceDimension(store);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (loading || i18nLoading || userInfo.loading) {
    return (
      <div className='white-loader'>
        <CrmLoader type='RevolvingDot' color='#00BFFF' height={100} width={100} loading={false} />
      </div>
    );
  }

  return (
    <>
      {(!prjInfo.data || prjInfo.loading) && <CrmLoader z loading />}
      <SnackbarFeedback />
      <SnackbarError />
      <Router>
        <JwtChecker />
        <Switch>
          <Route path='/:path?' component={LayoutRoot} />
        </Switch>
      </Router>
    </>
  );
};

export const LayoutRoot = () => {
  const store = useStore();
  //TODO: add some validation here and inform user if tenant is invalid
  if (store.baseUrl) {
    return (
      <Router basename={store.baseUrl}>
        <Switch>
          <Route exact path='/login' component={Login} />
          <Route exact path='/resetPassword' component={ActivateAccount} />
          <Route exact path='/forgotPassword' component={ForgotPassword} />
          <Route exact path='/changePassword' component={ChangePassword} />
          <Route exact path='/support' component={Support} />
          {/* TUTTE LE ROUTE, ORDINATE PER ORDER */}
          {RouteConsts.sort((a, b) => a.order - b.order).map((el) => (
            <PrivateRoute
              breadcrumb={el.breadcrumb}
              exact={el.exact}
              key={el.order}
              path={el.to}
              ToRender={el.component}
              title={el.title}
            />
          ))}

          {/* DEFAULT */}
          <PrivateRoute path={"/"} ToRender={Home} title={"Dashboard"} />
        </Switch>
      </Router>
    );
  }
  return null;
};
