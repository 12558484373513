class UserService {
  constructor(projectBaseUrl) {
    const projectHost = projectBaseUrl?.endsWith("/")
      ? projectBaseUrl.slice(0, -1)
      : projectBaseUrl;
    this.baseUserURL = `${projectHost}${process.env.REACT_APP_USER_API_BASE_URL}`;
    this.initRequest = {
      method: "GET",
      referrerPolicy: "unsafe-url",
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  async subscribeToNewsletterApi(
    token,
    projectId,
    { projectHostKey, privacyPolicy, subscription, projectArea, projectDefaultLang },
  ) {
    const response = await fetch(`${this.baseUserURL}newsletter/by-token/${token}/${projectId}`, {
      ...this.initRequest,
      method: "POST",
      body: JSON.stringify({
        projectHostKey,
        projectArea,
        privacyPolicy,
        subscription,
        projectDefaultLang,
      }),
    });
    if (!response.ok) {
      throw new Error(`Status: ${response.status}`);
    }
    return response;
  }
}

export default UserService;
